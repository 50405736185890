import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pl from 'vuetify/es5/locale/pl'

Vue.use(Vuetify)

const mq = window.matchMedia('(prefers-color-scheme: dark)')
const dark
  = localStorage.dark == null
    ? mq.matches
    : JSON.parse(localStorage.dark)

const vuetify = new Vuetify({
  lang: {
    locales: { pl },
    current: 'pl',
  },
  theme: {
    dark,
    themes: {
      light: {
        primary: '#75C402',
        secondary: '#5c6bc0',
        accent: '#3f51b5',
        darkBlue: '#21455B',
        background: '#FFF',
        neutral: '#000000',
        tone: '#FFF',
      },
      dark: {
        primary: '#75C402',
        secondary: '#5c6bc0',
        accent: '#3f51b5',
        darkBlue: '#21455B',
        neutral: '#ffffff',
        tone: '#343434',
      },
    },
    options: {
      variations: false,
      minifyTheme(css) {
        // @ts-expect-error
        return import.meta.env.NODE_ENV === 'production'
          ? css.replace(
            /[\r\n|\r|\n]/g,
            '',
          )
          : css
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
})

vuetify.framework.theme.dark
  = localStorage.dark == null
    ? mq.matches
    : JSON.parse(localStorage.dark)
mq.addListener((event) => {
  vuetify.framework.theme.dark
    = localStorage.dark == null
      ? event.matches
      : JSON.parse(localStorage.dark)
})

export default vuetify
