import { errorDefault } from '../helpers/snackbar'
import { mapStats } from '../models/StatsModel'
import { getStats } from '../firebase/firestoreStats'
import store from '.'

function onError(error) {
  console.error(error)
  store.dispatch(
    'shared/setError',
    error,
  )
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
  store.dispatch(
    'shared/setLoading',
    false,
  )
}

function initialState() {
  return {
    stats: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getStats(
      {
        commit,
        rootState: {
          app: {
            userData: { company },
          },
        },
      },
      { dateStart, dateEnd },
    ) {
      store.dispatch('shared/clearError')
      store.dispatch(
        'shared/setLoading',
        true,
      )

      const onSuccess = ({ docs }) => {
        commit(
          'GET_STATS_SUCCESS',
          docs.map(mapStats),
        )
        store.dispatch(
          'shared/setLoading',
          false,
        )
      }

      getStats(
        company,
        dateStart,
        dateEnd,
      )
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_STATS_SUCCESS(_state, stats) {
      _state.stats = stats
    },
  }
  ,
}
