import { errorDefault } from '../helpers/snackbar'
import { mapMissingCompany } from '../models/MissingCompanyModel'
import { getMissingCompanies, updatetMissingCompany } from '../firebase/firestoreMissingCompanies'
import store from '.'

function mapMissingCompanies(item, status) {
  return missingCompany => (missingCompany?.reference?.id === item?.reference?.id
    ? {
        ...missingCompany,
        status,
      }
    : missingCompany
  )
}

function initialState() {
  return {
    data: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getMmissingCompanies({
      commit,
      rootState: {
        company: { company },
      },
    }) {
      commit('GET_REQUEST')
      store.dispatch('shared/init')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_SUCCESS',
          docs.map(mapMissingCompany),
        )
        store.dispatch('shared/success')
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      getMissingCompanies(company.reference)
        .then(onSuccess)
        .catch(onError)
    },
    updateMmissingCompanyState({
      commit,
      rootState: {
        app: {
          userData,
        },
      },

    }, { item, status }) {
      commit('UPDATE_MISSING_COMPANY_STATE_REQUEST')

      const onSuccess = () => {
        commit(
          'UPDATE_MISSING_COMPANY_STATE_SUCCESS',
          {
            item,
            status,
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_MISSING_COMPANY_STATE_FAILURE',
          error,
        )

        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      updatetMissingCompany(
        item,
        status,
        userData,
      ).then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },
    GET_REQUEST() {
    },
    GET_SUCCESS(_state, data) {
      _state.data = data
    },
    GET_FAILURE() {
    },

    UPDATE_MISSING_COMPANY_STATE_REQUEST(_state) {
      _state.loading = true
      _state.error = null
    },
    UPDATE_MISSING_COMPANY_STATE_SUCCESS(_state, { item, status }) {
      _state.loading = false
      _state.error = null
      _state.data = _state.missingCompanies
        ? _state.missingCompanies.map(mapMissingCompanies(
          item,
          status,
        ))
        : _state.missingCompanies
    },
    UPDATE_MISSING_COMPANY_STATE_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
  },
}
