import i18n from '~/i18n'

const TIMEOUT = 6000

function initialState() {
  return {
    visible: false,
    color: 'primary',
    text: 'works',
    timeout: 6000,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    showSnackbar({ commit }, { color, text, timeout }) {
      commit(
        'SHOW_SNACKBAR',
        {
          color: color || 'primary',
          text: text || i18n.t('FormView.saved'),
          timeout: timeout || 6000,
        },
      )
      setTimeout(
        () => {
          commit('HIDE_SNACKBAR')
        },
        TIMEOUT,
      )
    },
    showSnackbarInfinity({ commit }, { color, text }) {
      commit(
        'SHOW_SNACKBAR',
        {
          color: color || 'primary',
          text: text || i18n.t('FormView.saved'),
          timeout: -1,
        },
      )
    },
    hideSnackbar({ commit }) {
      commit('HIDE_SNACKBAR')
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SHOW_SNACKBAR(_state, { color, text, timeout }) {
      _state.color = color
      _state.text = text
      _state.timeout = timeout
      _state.visible = true
    },
    HIDE_SNACKBAR(_state) {
      _state.timeout = 6000
      _state.visible = false
    },
  },
}
