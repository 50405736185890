import { getDoc, getDocs, query, serverTimestamp, where, writeBatch } from 'firebase/firestore'
import { collectionUsers } from '../firestoreWrappers'
import { ROLE } from '~/helpers/roles'
import { mapSuppliers } from '~/models/SupplierModel'
import { mapCustomers } from '~/models/CustomerModel'
import { firestore } from '~/firebaseCore'

const getSupplierDoc = reference => getDoc(reference)

function initialState() {
  return {
    employees: [],
    suppliers: [],

    loading: false,
    error: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getEmployees({ commit, rootState: { app: { userData } } }, { suppliers }) {
      commit('GET_EMPLOYEES_INIT')

      const employees = []
      const uniqueSuppliers = []

      const onMidSuccess = (docs) => {
        docs.docs.forEach((doc) => {
          const mappedDoc = mapCustomers(doc)
          // @ts-expect-error
          if (!employees.map(employee => employee.reference.id).includes(mappedDoc.reference.id) && mappedDoc.reference.id !== userData.reference.id) {
            // @ts-expect-error
            employees.push(mappedDoc)

            // @ts-expect-error
            mappedDoc.work.suppliers.forEach((supplier) => {
              // @ts-expect-error
              if (!uniqueSuppliers.map(uniqueSupplier => uniqueSupplier.id).includes(supplier.id))
              // @ts-expect-error
                uniqueSuppliers.push(supplier)
            })
          }
        })
      }

      const onFailure = (error) => {
        console.error(error)
        commit('GET_EMPLOYEES_FAILURE', error)
      }

      const promises = suppliers.map(supplier =>
        getDocs(
          query(
            collectionUsers,
            where('work.suppliers', 'array-contains', supplier),
            where('role', '==', ROLE.MANAGER),
          ),
        ),
      )

      Promise.all(promises)
        .then((results) => {
          results.forEach(onMidSuccess)
          const supplierPromises = uniqueSuppliers.map(uniqueSupplier => getSupplierDoc(uniqueSupplier))

          Promise.all(supplierPromises).then((supplierResults) => {
            commit('GET_EMPLOYEES_SUCCESS', { employees })
            commit('GET_SUPPLIERS_SUCCESS', { suppliers: supplierResults.map(mapSuppliers(null)) })
          })
        })
        .catch(onFailure)
    },

    async updateSupplierSubsidy({ commit, rootState: { app: { userData } } }, { reference, subsidy, price }) {
      commit('UPDATE_EMPLOYEE_INIT')

      const onSuccess = () => {
        commit('UPDATE_EMPLOYEE_SUCCESS')
      }

      const onFailure = (error) => {
        commit('UPDATE_EMPLOYEE_FAILURE', error)
      }

      const batch = writeBatch(firestore)

      batch.update(reference,
        {
          subsidy: { ...subsidy, subsidyPrice: price },
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      await batch.commit()
        .then(onSuccess)
        .catch(onFailure)
    },

    async updateCustomerSubsidy({ commit, rootState: { app: { userData } } }, { reference, price }) {
      commit('UPDATE_EMPLOYEE_INIT')

      const onSuccess = () => {
        commit('UPDATE_EMPLOYEE_SUCCESS')
      }

      const onFailure = (error) => {
        commit('UPDATE_EMPLOYEE_FAILURE', error)
      }

      const batch = writeBatch(firestore)

      batch.update(reference,
        {
          subsidyBudget: price,
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      await batch.commit()
        .then(onSuccess)
        .catch(onFailure)
    },

    async updateActive({ commit, rootState: { app: { userData } } }, { reference, active }) {
      commit('UPDATE_EMPLOYEE_INIT')

      const onSuccess = () => {
        commit('UPDATE_EMPLOYEE_SUCCESS')
      }

      const onFailure = (error) => {
        commit('UPDATE_EMPLOYEE_FAILURE', error)
      }

      const batch = writeBatch(firestore)

      batch.update(reference,
        {
          isActive: active,
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      await batch.commit()
        .then(onSuccess)
        .catch(onFailure)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_EMPLOYEES_INIT(_state) {
      _state.error = null
      _state.loading = true
    },
    GET_EMPLOYEES_SUCCESS(_state, { employees }) {
      _state.loading = false
      _state.error = null
      _state.employees = employees
    },
    GET_EMPLOYEES_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },

    GET_SUPPLIERS_SUCCESS(_state, { suppliers }) {
      _state.suppliers = suppliers
    },

    UPDATE_EMPLOYEE_INIT(_state) {
      _state.error = null
      _state.loading = true
    },
    UPDATE_EMPLOYEE_SUCCESS(_state) {
      _state.loading = false
      _state.error = null
    },
    UPDATE_EMPLOYEE_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
  },
}
