import { getDocs, query, serverTimestamp, updateDoc, where, writeBatch } from 'firebase/firestore'
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { collectionOwners } from '../firestoreWrappers'
import { firestore, storage } from '../firebaseCore'
import { mapCompany } from '../models/CompanyModel'
import { mapOwners } from '../models/ManagerModel'
import { collectionCompanies } from '../firebase/firestoreCompanies'
import store from '.'

const mapPromises = item => getDownloadURL(item)
function mapFunc(data) {
  return (item, index) => ({
    name: item.name,
    url: data[index],
  })
}

function initialState() {
  return {
    companies: null,

    agreements: null,
    loadingAgreements: false,
    errorAgreements: null,

    owners: null,
    loadingOwners: false,
    errorOwners: null,
  }
}

function mapByOwner(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id)
      ownerData.loading = true

    return ownerData
  }
}

function mapByOwner2(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id) {
      ownerData.isOwner = !owner?.isOwner
      delete ownerData.loading
    }
    return ownerData
  }
}

function mapByOwner3(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id)
      delete ownerData.loading

    return ownerData
  }
}

function mapByCompany(company, agreement) {
  return (companyData) => {
    if (companyData.reference.id === company.reference.id)
      companyData.agreement = agreement

    return companyData
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getCompanies({ commit }) {
      store.dispatch('shared/init')

      const onSuccess = ([{ docs: companies }]) => {
        commit(
          'GET_SUCCESS',
          companies.map(mapCompany),
        )
        store.dispatch('shared/success')
      }

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          'shared/error',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      Promise.all([getDocs(collectionCompanies)])

        .then(onSuccess)
        .catch(onError)
    },

    getOwners({ commit }, company) {
      commit('GET_COMPANIES_OWNERS_REQUEST')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_COMPANIES_OWNERS_SUCCESS',
          docs.map(mapOwners),
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_COMPANIES_OWNERS_ERROR',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      getDocs(query(
        collectionOwners,
        where(
          'company',
          '==',
          company.reference,
        ),
      ))
        .then(onSuccess)
        .catch(onError)
    },
    getAgreements({ commit }, company) {
      commit('GET_AGREEMENTS_REQUEST')

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_AGREEMENTS_ERROR',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const onSuccess = ({ items }) => {
        Promise.all(items.map(mapPromises))
          .then((data) => {
            commit(
              'GET_AGREEMENTS_SUCCESS',
              items.map(mapFunc(data)),
            )
          })
          .catch(onError)
      }

      const path = `${company.reference.id}/docs/`

      listAll(ref(
        storage,
        path,
      ))
        .then(onSuccess)
        .catch(onError)
    },
    updateOwnerStatus(
      {
        commit,
        rootState: {
          companies: { companies },
          app: { userData },
        },
      },
      owner,
    ) {
      const newOwnerData = {
        isOwner: !owner?.isOwner,
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      }

      const byId = ({ reference }) => reference.id === owner.company.id

      const [ownerCompany] = companies.filter(byId)
      const acceptedByObject = ownerCompany?.agreement?.acceptedBy || {}

      if (!owner?.isOwner)
        acceptedByObject[owner.reference.id] = false
      else
        delete acceptedByObject[owner.reference.id]

      const newCompanyAgreement = {
        agreement: {
          ...ownerCompany?.agreement || {},
          acceptedBy: acceptedByObject,
        },
      }

      commit(
        'UPDATE_OWNER_STATUS_REQUEST',
        owner,
      )

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_OWNER_STATUS_ERROR',
          owner,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const onSuccess = () => {
        commit(
          'UPDATE_OWNER_STATUS_SUCCESS',
          owner,
        )
        commit(
          'UPDATE_COMPANY_SUCCESS',
          {
            company: ownerCompany,
            agreement: newCompanyAgreement.agreement,
          },
        )
      }

      const newCompanyAgreementData = {
        ...newCompanyAgreement,
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      }

      const batch = writeBatch(firestore)

      batch
        .update(
          owner.reference,
          newOwnerData,
        )
        .update(
          ownerCompany.reference,
          newCompanyAgreementData,
        )
        .commit()
        .then(onSuccess)
        .catch(onError)
    },
    uploadAgreement({ commit }, { selectedFile, company }) {
      const activeFile = `${new Date().toISOString()}${selectedFile.name}`
      const path = `${company.reference.id}/docs/${activeFile}`

      const onSuccess = (result) => {
        getDownloadURL(result.ref).then((url) => {
          const agreement = {
            name: result.metadata.name,
            url,
          }
          commit(
            'UPDATE_AGREEMENT_SUCCESS',
            agreement,
          )
        })
      }

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      uploadBytes(
        ref(
          storage,
          path,
        ),
        selectedFile,
      )
        .then(onSuccess)
        .catch(onError)
    },
    updateCompanyAgreement({
      commit,
      rootState: {
        app: {
          userData,
        },
      },
    }, { agreement, company }) {
      const onSuccess = () => {
        commit(
          'UPDATE_COMPANY_SUCCESS',
          {
            company,
            agreement: {
              ...company?.agreement || {},
              ...agreement,
            },
          },
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      updateDoc(
        company.reference,
        {
          agreement: {
            ...company?.agreement || {},
            ...agreement,
          },
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),

        },
      )
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_SUCCESS(_state, companies) {
      _state.companies = companies
    },

    GET_COMPANIES_OWNERS_REQUEST(_state) {
      _state.loadingOwners = true
      _state.errorOwners = null
    },
    GET_COMPANIES_OWNERS_SUCCESS(_state, data) {
      _state.loadingOwners = false
      _state.owners = data
    },
    GET_COMPANIES_OWNERS_ERROR(_state, error) {
      _state.loadingOwners = false
      _state.errorOwners = error
    },

    GET_AGREEMENTS_REQUEST(_state) {
      _state.loadingAgreements = true
      _state.errorAgreements = null
    },
    GET_AGREEMENTS_SUCCESS(_state, data) {
      _state.loadingAgreements = false
      _state.agreements = data
    },
    GET_AGREEMENTS_ERROR(_state, error) {
      _state.loadingAgreements = false
      _state.errorAgreements = error
    },

    UPDATE_OWNER_STATUS_REQUEST(_state, owner) {
      _state.owners = _state.owners.map(mapByOwner(owner))
    },
    UPDATE_OWNER_STATUS_SUCCESS(_state, owner) {
      _state.owners = _state.owners.map(mapByOwner2(owner))
    },
    UPDATE_OWNER_STATUS_ERROR(_state, owner) {
      _state.owners = _state.owners.map(mapByOwner3(owner))
    },

    UPDATE_COMPANY_SUCCESS(_state, { company, agreement }) {
      _state.companies = _state.companies.map(mapByCompany(company, agreement))
    },

    UPDATE_AGREEMENT_SUCCESS(_state, agreement) {
      _state.agreements = [
        ..._state.agreements || [],
        agreement,
      ]
    },
  },
}
