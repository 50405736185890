import { computed, getCurrentInstance } from 'vue'

export function useVuetify() {
  // @ts-expect-error getCurrentInstance
  const { $root } = getCurrentInstance().proxy
  // @ts-expect-error
  const { $vuetify } = $root

  const setTheme = (theme) => {
    $vuetify.theme.dark = theme
  }

  const isDark = computed(() => $vuetify.theme.dark)

  const isPhone = computed(() => {
    const { name } = $vuetify.breakpoint
    return name === 'xs' || name === 'sm'
  })

  return {
    $vuetify,
    isPhone,
    isDark,
    setTheme,
  }
}
