export interface IAdress {
  postCode: string | null
  street: string | null
  city: string | null
  flatNumber: string | null
  floor: string | null
  doorCode: string | null
  description: string | null
  address: string | null
}

export class AddressModel implements IAdress {
  postCode: string | null
  street: string | null
  city: string | null
  flatNumber: string | null
  floor: string | null
  doorCode: string | null
  description: string | null
  address: string | null

  constructor(data: Partial<IAdress>) {
    this.postCode = data.postCode || null
    this.street = data.street || null
    this.city = data.city || null
    this.flatNumber = data.flatNumber || null
    this.floor = data.floor || null
    this.doorCode = data.doorCode || null
    this.description = data.description || null
    this.address = data.address || null
  }

  toMap() {
    return {
      postCode: this.postCode?.trim() || null,
      street: this.street,
      city: this.city,
    }
  }
}
