export default class MacroModel {
  name: string | null
  nameEN: string | null
  units: string | null
  value: number | null

  constructor(data) {
    this.name = data.name || null
    this.nameEN = data.nameEN || null
    this.units = data.units || null
    this.value = Number(data.value)
  }

  toMap() {
    return {
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      units: this.units?.trim() || null,
      value: Number(this.value),
    }
  }

  toStringMap() {
    return {
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      units: this.units?.trim() || null,
      value: String(this.value),
    }
  }
}
