import { collection, getDocs, onSnapshot, query, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { firestore } from '../firebaseCore'

export const collectionMessages = collection(
  firestore,
  'messages',
)

export const getMessagesQuery = () => getDocs(collectionMessages)

export function getVisibleToDoCateringOrdersQuery(onSuccess, onError) {
  return onSnapshot(
    query(
      collectionMessages,
      where(
        'category',
        '==',
        'catering-order',
      ),
      where(
        'isVisible',
        '==',
        true,
      ),
    ),
    onSuccess,
    onError,
  )
}

export function getCompanyCateringOrdersQuery(company, onSuccess, onError) {
  return onSnapshot(
    query(
      collectionMessages,
      where(
        'company',
        '==',
        company,
      ),
    ),
    onSuccess,
    onError,
  )
}

export function updateMessage(item, status, userData) {
  return updateDoc(
    item.reference,
    {
      status,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    },
  )
}
