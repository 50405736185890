import { EmployeeModel } from './EmployeeModel'

export class OwnerModel extends EmployeeModel {
  isOwner: string | null

  constructor(data, reference?) {
    super(
      data,
      reference,
    )
    this.isOwner = data.isOwner || false
  }

  toMap() {
    return {
      ...super.toMap(),
      isOwner: this.isOwner,
    }
  }

  toMapCreate() {
    return {
      ...super.toMapCreate(),
      isOwner: this.isOwner,
    }
  }
}
