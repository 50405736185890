import type { Ref } from 'vue'
import { ref } from 'vue'

export default {
  setup() {
    const refreshing = ref(false)
    const registration: Ref<null | { waiting: { postMessage: ({ type }) => void } }> = ref(null)
    const updateExists = ref(false)

    const updateAvailable = (event) => {
      registration.value = event.detail
      updateExists.value = true

      if (!registration.value || !registration.value?.waiting)
        return

      updateExists.value = false

      registration.value?.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    // const refreshApp = () => {
    //   updateExists.value = false
    //   if (!registration.value || !registration.value.waiting)
    //     return
    //   registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
    // }

    if (document) {
      document.addEventListener(
        'swUpdated',
        updateAvailable,
        { once: true },
      )
    }

    if (navigator?.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        () => {
          if (refreshing.value)
            return

          refreshing.value = true
          window.location.reload()
        },
      )
    }

    return {
      refreshing,
      registration,
      updateExists,
    }
  },
}
