import type { DocumentReference } from 'firebase/firestore'
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, serverTimestamp, updateDoc, where, writeBatch } from 'firebase/firestore'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import dayjs from 'dayjs'
import { mapOrders } from './orders'
import { getEndOfDay, getStartOfDay } from '~/helpers/time'
import type { CustomerModel } from '~/models/CustomerModel'
import { mapCustomers } from '~/models/CustomerModel'
import OrderModel from '~/models/OrderModel'
import type { SupplierModel } from '~/models/SupplierModel'
import { mapSuppliersSubsidy } from '~/models/SupplierModel'
import { mapMenuModel } from '~/models/MenuModel'
import type { MenuModel } from '~/models/MenuModel'
import { firestore } from '~/firebaseCore'
import type { CompanyModel } from '~/models/CompanyModel'

const collectionSuppliers = collection(firestore, 'supplier')
const collectionUsers = collection(firestore, 'users')
const collectionOrders = collection(firestore, 'orders')
const collectionMenus = collection(firestore, 'menu')
const collectionMenuCategories = collection(firestore, 'menuCategories')

function getSuppliersQuery(companyRef: DocumentReference) {
  return getDocs(
    query(
      collectionSuppliers,
      where('company', '==', companyRef),
      where('subsidy', '!=', null),
    ))
}

function getUsersQuery(supplierRef: DocumentReference) {
  return getDocs(
    query(
      collectionUsers,
      where('work.suppliers', 'array-contains', supplierRef),
      where('role', '==', 'customer'),
    ),
  )
}

function getOrdersQuery(supplierRef: DocumentReference, date: Date, tomorrowOrderTime1: any) {
  const tomorrowOrderTime = new Date(tomorrowOrderTime1.toDate())

  let start = getStartOfDay(date)
  start.setHours(tomorrowOrderTime.getHours(), tomorrowOrderTime.getMinutes(), 0, 0)
  const end = getEndOfDay(date)
  end.setHours(tomorrowOrderTime.getHours(), tomorrowOrderTime.getMinutes(), 0, 0)
  // @ts-expect-error todo
  start = dayjs(start)
  // @ts-expect-error todo
  start = start.add(-1, 'day')

  return query(
    collectionOrders,
    where('supplier', '==', supplierRef),
    // @ts-expect-error todo
    where('date', '>=', new Timestamp(Math.floor(start.toDate().getTime() / 1000), 0)),
    where('date', '<=', new Timestamp(Math.floor(end.getTime() / 1000), 0)),
  )
}

function getMenusQuery(companyRef: DocumentReference, dayOfWeek: string) {
  return getDocs(
    query(
      collectionMenus,
      where('companyRef', '==', companyRef),
      where('activeDays', 'array-contains', dayOfWeek),
      where('isActive', '==', true),
    ),
  )
}

export const useSuppliersSubsidyStore = defineStore('suppliersSubsidy', () => {
  const loading = ref(false)
  const error = ref(null)
  const suppliers: Ref<SupplierModel[]> = ref([])
  const customers: Ref<CustomerModel[]> = ref([])
  const orders: Ref<OrderModel[]> = ref([])
  const menus: Ref<MenuModel[]> = ref([])
  const menuCategories: Ref<any[]> = ref([])
  const company: any = ref(null)
  let unsubscribe: any = null
  let unsubscribeArray: any[] = []

  let customersArray: { supplierID: string; customers: CustomerModel[] }[] = []
  let ordersArray: { supplierID: string; date: string; orders: OrderModel[] }[] = []
  let menusArray: { day: string; menus: MenuModel[] }[] = []

  const saveUnsubscribe = (value) => {
    unsubscribe = value
  }

  const resetState = () => {
    if (unsubscribe) {
      unsubscribe()
      saveUnsubscribe(null)
    }

    if (unsubscribeArray.length) {
      unsubscribeArray.forEach(item => item())
      unsubscribeArray = []
    }

    loading.value = false
    error.value = null
    suppliers.value = []
    customers.value = []
    orders.value = []
    menus.value = []
    menuCategories.value = []
    company.value = null

    customersArray = []
    ordersArray = []
    menusArray = []
  }

  const storeInit = () => {
    loading.value = true
    error.value = null
  }

  const storeSuccess = () => {
    loading.value = false
    error.value = null
  }

  const storeError = (arg) => {
    loading.value = false
    error.value = arg
  }

  const getSuppliers = (companyRef: DocumentReference) => {
    storeInit()

    const onSuccess = (arg) => {
      suppliers.value = arg.docs.map(mapSuppliersSubsidy)
      storeSuccess()
    }

    const onFailure = (arg) => {
      storeError(arg)
    }

    getSuppliersQuery(companyRef)
      .then(onSuccess)
      .catch(onFailure)
  }

  const getSuppliers2 = (supplierRefs: DocumentReference[]) => {
    storeInit()

    const onSuccess = (arg) => {
      suppliers.value = arg.map(mapSuppliersSubsidy)
      storeSuccess()
    }

    const onFailure = (arg) => {
      storeError(arg)
    }

    const promises = supplierRefs.map(supplierRef => getDoc(supplierRef))

    Promise.all(promises)
      .then(onSuccess)
      .catch(onFailure)
  }

  const getCustomersInSupplier = (supplier: DocumentReference) => {
    const customerObj = customersArray.find(item => item.supplierID === supplier.id)

    if (customerObj) {
      customers.value = customerObj.customers
      return
    }

    storeInit()

    const onSuccess = (arg) => {
      customers.value = arg.docs.map(mapCustomers)
      customersArray.push({
        supplierID: supplier.id,
        customers: customers.value,
      })
      storeSuccess()
    }

    const onFailure = (arg) => {
      storeError(arg)
    }

    getUsersQuery(supplier)
      .then(onSuccess)
      .catch(onFailure)
  }

  const updateSupplier = (supplier: SupplierModel) => {
    storeInit()

    updateDoc(supplier.reference, supplier.toMap())
      .then(storeSuccess)
      .catch(storeError)
  }

  const updateAllUsers = async (users: CustomerModel[]) => {
    storeInit()

    const promises = users.map(user => updateDoc(user.reference, user.toMap()))
    await Promise.all(promises)
      .then(storeSuccess)
      .catch(storeError)
  }

  const updateUser = (user: CustomerModel) => {
    storeInit()

    updateDoc(user.reference, user.toMap())
      .then(storeSuccess)
      .catch(storeError)
  }

  const mapDate = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  const getOrders = (supplierRef: DocumentReference, date: Date, company2: CompanyModel) => {
    const ordersObj = ordersArray.find(item => item.supplierID === supplierRef.id && item.date === mapDate(date))

    if (ordersObj) {
      orders.value = ordersObj.orders
      return
    }
    orders.value = []

    if (unsubscribe) {
      unsubscribe()
      saveUnsubscribe(null)
    }

    if (unsubscribeArray.length) {
      unsubscribeArray.forEach(item => item())
      unsubscribeArray = []
    }

    storeInit()

    const onSuccess = (arg) => {
      orders.value = arg.docs.map(mapOrders)
      ordersArray.push({
        supplierID: supplierRef.id,
        date: mapDate(date),
        orders: orders.value,
      })
      storeSuccess()
    }

    const onError = (arg) => {
      storeError(arg)
    }

    const unsubscribeNew = onSnapshot(getOrdersQuery(supplierRef, date, company2.tomorrowOrderTime), onSuccess, onError)
    saveUnsubscribe(unsubscribeNew)
  }

  const getMenusForToday = (companyRef: DocumentReference, dayOfWeek: string) => {
    const menusObj = menusArray.find(menu => menu.day === dayOfWeek)

    if (menusObj) {
      menus.value = menusObj.menus
      return
    }

    storeInit()

    const onSuccess = (arg) => {
      menus.value = arg.docs.map(mapMenuModel)
      menusArray.push({
        day: dayOfWeek,
        menus: menus.value,
      })
      storeSuccess()
    }

    const onError = (arg) => {
      storeError(arg)
    }

    getMenusQuery(companyRef, dayOfWeek)
      .then(onSuccess)
      .catch(onError)
  }

  const getMenuCategories = (companyRef: DocumentReference) => {
    storeInit()

    const onSuccess = (arg) => {
      menuCategories.value = arg.data().menuCategories
      storeSuccess()
    }

    const onError = (arg) => {
      storeError(arg)
    }

    getDoc(doc(collectionMenuCategories, companyRef.id))
      .then(onSuccess)
      .catch(onError)
  }

  const rejectOrder = (userData, order: OrderModel) => {
    storeInit()
    if (order.reference) {
      const batch = writeBatch(firestore)

      batch.update(order.reference,
        {
          completementStatus: 'rejected',
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      batch.commit()
        .then(storeSuccess)
        .catch(storeError)
    }
  }

  const createOrder = async (orderData) => {
    storeInit()

    try {
      const batch = writeBatch(firestore)
      const data = await getDoc(doc(collectionOrders, 'order_number'))
      const index = data.data()?.number + 1 || 0
      orderData.orderNumber = index

      const orderRef = await addDoc(collectionOrders, orderData)

      batch.update(data.ref, { number: index })
      batch.commit()

      const obj = ordersArray.find(item => item.supplierID === orderData.supplier.id && item.date === mapDate(orderData.date))
      if (obj)
        obj.orders.push(new OrderModel(orderData, orderRef))

      storeSuccess()
    }
    catch (arg) {
      storeError(arg)
    }
  }

  const updateOrder = async (orderData, orderRef: DocumentReference) => {
    storeInit()

    updateDoc(
      doc(collectionOrders, orderRef.id),
      orderData,
    )
      .then(storeSuccess)
      .catch(storeError)
  }

  const getCompany = (companyRef: DocumentReference) => {
    getDoc(companyRef).then(arg => company.value = arg.data()).catch(() => company.value = null)
  }

  const updateSubsidyBudget = (customer: CustomerModel, newBudget: number) => {
    const batch = writeBatch(firestore)

    batch.update(
      customer.reference,
      {
        subsidyBudget: newBudget,
      },
    )

    batch.commit()
  }

  const updateSubsidyPrice = (supplier: SupplierModel, newPrice: number) => {
    const batch = writeBatch(firestore)

    batch.update(
      supplier.reference,
      {
        supplier: {
          subsidyPrice: newPrice,
        },
      },
    )

    batch.commit()
  }

  const updateOrdersOnline = (tabCustomers: CustomerModel[], value: boolean) => {
    const customerRefs = tabCustomers.map(customer => customer.reference)
    const batch = writeBatch(firestore)

    customerRefs.forEach((customerRef) => {
      batch.update(
        customerRef,
        {
          isOrderWithSubsidy: value,
        },
      )
    })
    batch.commit()
  }

  return {
    loading,
    suppliers,
    customers,
    orders,
    menus,
    menuCategories,
    company,
    resetState,
    getSuppliers,
    getSuppliers2,
    getCustomersInSupplier,
    updateSupplier,
    updateAllUsers,
    updateUser,
    getOrders,
    getMenusForToday,
    getMenuCategories,
    rejectOrder,
    createOrder,
    updateOrder,
    getCompany,
    updateSubsidyBudget,
    updateSubsidyPrice,
    updateOrdersOnline,
  }
})
