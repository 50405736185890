<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue'
import { useVuex } from '../../useVuex'
import i18n from '~/i18n'

const { getState, getAction } = useVuex()

const visible = getState('visible', 'snackbar')
const color = getState('color', 'snackbar')
const timeout = getState('timeout', 'snackbar')
const text = getState('text', 'snackbar')

onMounted(() => {
  const hideSnackbar = () => {
    getAction('hideSnackbar', 'snackbar')()
  }

  const showSnackbarInfinity = () => {
    getAction(
      'showSnackbarInfinity',
      'snackbar',
    )({
      color: 'primary',
      text: i18n.t('OfflineMode.offline'),
    })
  }

  window.addEventListener('online', hideSnackbar)
  window.addEventListener('offline', showSnackbarInfinity)
})

onBeforeUnmount(() => {
  window.removeEventListener('online', () => { })
  window.removeEventListener('offline', () => { })
})
</script>

<template>
  <v-snackbar
    :value="visible"
    :color="color || 'primary'"
    :timeout="timeout || 600"
  >
    <v-row class="justify-center">
      <span class="text-h6">
        {{ text }}
      </span>
    </v-row>
  </v-snackbar>
</template>
