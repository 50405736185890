<script setup lang="ts">
import update from './mixins/update'
import { useVuex } from './useVuex'

update.setup()

const { getState } = useVuex()
const userData = getState('userData', 'app')
</script>

<template>
  <v-app>
    <TheAppBarUser v-if="userData" />

    <TheAppBarGuest v-else />

    <v-main v-if="userData">
      <router-view />
    </v-main>

    <router-view v-else />

    <TheCookies />

    <TheAppSnackbar />
  </v-app>
</template>

<style>
.v-application {
  font-family: "Poppins", sans-serif !important;
}

@media print {

  .v-main {
    padding: 0 !important;
    margin: 0 !important;
  }
}
</style>
