export class DeliverRankingModel {
  firstName: string | null
  lastName: string | null
  city: string | null
  clientsCount: string | null

  constructor(deliver, clientsCount) {
    this.firstName = deliver.profile.firstName
    this.lastName = deliver.profile.lastName
    this.city = deliver.profile.city
    this.clientsCount = clientsCount
  }
}
