import type { DocumentReference, FieldValue } from 'firebase/firestore'

export class InvoiceModel {
  company: DocumentReference | null
  nip: string | null
  companyName: string | null
  isOverflowInvoice: boolean
  createdByUser: DocumentReference | null
  lastUpdateByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.company = data.company || true
    this.nip = data.nip || null
    this.companyName = data.companyName || null
    this.isOverflowInvoice = data.isOverflowInvoice || false
    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.reference = reference
  }

  toMap() {
    return {
      company: this.company,
      nip: this.nip,
      companyName: this.companyName,
      isOverflowInvoice: this.isOverflowInvoice,
      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }
}

export function mapData(data) {
  return new InvoiceModel(
    data.data(),
    data.ref,
  )
}
