import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import pl from './locales/pl.json'
import ru from './locales/ru.json'
import ua from './locales/ua.json'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const messages = {
    pl,
    en,
    ua,
    ru,
  }
  return messages
}

export default new VueI18n({
  fallbackLocale: 'en',
  locale: 'pl',
  messages: loadLocaleMessages(),
})
