import { addDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import {
  getReportBugOrSuggestionActive,
  getReportBugOrSuggestionActiveByCitiesActive,
  getReportBugOrSuggestionActiveByCitiesSolved,
  getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer,
  getReportWithoutCities,
  reportBugOrSuggestionCollection,
} from '../firestoreWrappers'
import { isManagerOrDeliver, isOwner } from '../helpers/roles'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { mapReportBugOrSuggestion } from '../models/ReportBugOrSuggestionModel'
import store from '.'

function initialState() {
  return {
    reportBugOrSuggestion: null,

    reportBugOrSuggestionSolved: null,
    reportBugOrSuggestionWaitingCustomerAnswer: null,
    reportBugOrSuggestionActive: null,
    reportBugOrSuggestionWithoutCities: null,

    unsubscribe: null,
    unsubscribe2: null,
    unsubscribe3: null,
    unsubscribe4: null,

    reportBugOrSuggestionLoading: false,
    reportBugOrSuggestionSuccess: false,
    reportBugOrSuggestionError: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({
      commit, rootState: {
        reportBugOrSuggestion: { unsubscribe, unsubscribe2, unsubscribe3, unsubscribe4 },
      },
    }) {
      if (unsubscribe)
        unsubscribe()

      if (unsubscribe2 || unsubscribe2) {
        unsubscribe()
        unsubscribe2()
        unsubscribe3()
        unsubscribe4()
      }

      commit('RESET_STATE')
    },

    getReportBugOrSuggestion({
      commit,
      rootState: {
        app: {
          userData: { company, profile: { cities } },
          user: { displayName },
        },
        reportBugOrSuggestion: { unsubscribe, unsubscribe2, unsubscribe3, unsubscribe4 },
      },
    }) {
      if (!company)
        return

      commit('GET_REPORT_BUG_OR_SUGGESTIONS_REQUEST')
      if (unsubscribe) {
        unsubscribe()
        commit(
          'SAVE_UNSUBSCRIBE',
          null,
        )
      }
      if (unsubscribe2 || unsubscribe3) {
        unsubscribe()
        unsubscribe2()
        unsubscribe3()
        unsubscribe4()
        commit(
          'SAVE_UNSUBSCRIBE2',
          {
            unsubscribe: null,
            unsubscribe2: null,
            unsubscribe3: null,
          },
        )
      }

      const onSuccess = ({ docs }) => {
        commit(
          'GET_REPORT_BUG_OR_SUGGESTIONS_SUCCESS',
          {
            reportBugOrSuggestion: docs.map(mapReportBugOrSuggestion),
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_REPORT_BUG_OR_SUGGESTIONS_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const onSuccess4 = key => ({ docs }) => {
        commit(
          'GET_REPORT_BUG_OR_SUGGESTIONS_BY_STATUS_SUCCESS',
          {
            key,
            reportBugOrSuggestion: (docs || []).map(mapReportBugOrSuggestion),
          },
        )
      }

      if (isOwner(displayName) || isManagerOrDeliver(displayName)) {
        if (isOwner(displayName)) {
          const unsubscribeNew = onSnapshot(
            getReportBugOrSuggestionActive(company),
            onSuccess,
            onError,
          )
          commit(
            'SAVE_UNSUBSCRIBE',
            unsubscribeNew,
          )
        }
        else {
          const unsubscribeNew = onSnapshot(
            getReportBugOrSuggestionActiveByCitiesActive(
              company,
              cities,
            ),
            onSuccess4('reportBugOrSuggestionActive'),
            onError,

          )

          const unsubscribeNew2 = onSnapshot(
            getReportBugOrSuggestionActiveByCitiesSolved(
              company,
              cities,
            ),
            onSuccess4('reportBugOrSuggestionSolved'),
            onError,

          )
          const unsubscribeNew4 = onSnapshot(
            getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer(
              company,
              cities,
            ),
            onSuccess4('reportBugOrSuggestionWaitingCustomerAnswer'),
            onError,

          )

          const unsubscribeNew3 = onSnapshot(
            getReportWithoutCities(company),
            onSuccess4('reportBugOrSuggestionWithoutCities'),
            onError,
          )

          commit(
            'SAVE_UNSUBSCRIBE2',
            {
              unsubscribe: unsubscribeNew,
              unsubscribe2: unsubscribeNew2,
              unsubscribe3: unsubscribeNew3,
              unsubscribe4: unsubscribeNew4,
            },
          )
        }
      }
      else {
        onError('Wrong site')
      }
    },
    updateReportBugOrSuggestion({
      commit,
      rootState: {
        app: {
          userData,
        },
      },
    }, { item, status }) {
      commit('UPDATE_REPORT_BUG_OR_SUGGESTION_STATE_REQUEST')

      const onSuccess = () => {
        commit('UPDATE_REPORT_BUG_OR_SUGGESTION_STATE_SUCCESS')
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_REPORT_BUG_OR_SUGGESTION_STATE_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      updateDoc(
        item.reference,
        {
          status,
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      ).then(onSuccess)
        .catch(onError)
    },
    sendCompanySuggestion({
      commit,
    }, { data }) {
      commit('SET_REPORT_BUG_OR_SUGGESTIONS_REQUEST')

      const onSuccess = () => {
        commit('SET_REPORT_BUG_OR_SUGGESTION_STATE_SUCCESS')
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'SET_REPORT_BUG_OR_SUGGESTION_STATE_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      addDoc(reportBugOrSuggestionCollection, data.toMap())
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SAVE_UNSUBSCRIBE(state, unsubscribe) {
      state.unsubscribe = unsubscribe
    },
    SAVE_UNSUBSCRIBE2(state, { unsubscribe, unsubscribe2, unsubscribe3, unsubscribe4 }) {
      state.unsubscribe = unsubscribe
      state.unsubscribe2 = unsubscribe2
      state.unsubscribe3 = unsubscribe3
      state.unsubscribe4 = unsubscribe4
    },

    SET_REPORT_BUG_OR_SUGGESTIONS_REQUEST(_state) {
      _state.reportBugOrSuggestionLoading = true
      _state.reportBugOrSuggestionError = null
    },
    SET_REPORT_BUG_OR_SUGGESTION_STATE_SUCCESS(_state) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = null
    },
    SET_REPORT_BUG_OR_SUGGESTION_STATE_FAILURE(_state, error) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = error
    },
    GET_REPORT_BUG_OR_SUGGESTIONS_REQUEST(_state) {
      _state.reportBugOrSuggestionLoading = true
      _state.reportBugOrSuggestionError = null
    },
    GET_REPORT_BUG_OR_SUGGESTIONS_SUCCESS(_state, { reportBugOrSuggestion }) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = null
      _state.reportBugOrSuggestion = reportBugOrSuggestion
    },
    GET_REPORT_BUG_OR_SUGGESTIONS_BY_STATUS_SUCCESS(_state, { key, reportBugOrSuggestion }) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = null
      _state[key] = reportBugOrSuggestion
    },
    GET_REPORT_BUG_OR_SUGGESTIONS_FAILURE(_state, error) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = error
    },

    UPDATE_REPORT_BUG_OR_SUGGESTION_STATE_REQUEST(_state) {
      _state.reportBugOrSuggestionLoading = true
      _state.reportBugOrSuggestionError = null
    },
    UPDATE_REPORT_BUG_OR_SUGGESTION_STATE_SUCCESS(_state) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = null
    },
    UPDATE_REPORT_BUG_OR_SUGGESTION_STATE_FAILURE(_state, error) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = error
    },
  },
}
