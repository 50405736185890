import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { addDoc, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref as storageRef, uploadString } from 'firebase/storage'
import { storage } from '../firebaseCore'
import type { NewsModel } from '../models/NewsModel'
import { mapNews } from '../models/NewsModel'
import store from '../store'
import { collectionNews, getNewsQuery, getNextNews } from '../firebase/firestoreNews'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { createAndUploadImage } from '~/helpers/uploadImage'

function onErrorShared(error) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}

export const useNewsStore = defineStore('news', () => {
  const news: Ref<NewsModel[] | null> = ref(null)
  const size = ref(null)
  const lastVisible = ref(null)
  const fetchLoading: Ref<boolean | null> = ref(null)
  const fetchError = ref(null)
  const createLoading: Ref<boolean | null> = ref(null)
  const createError = ref(null)

  const resetState = () => {
    news.value = null
    size.value = null
    lastVisible.value = null
    fetchLoading.value = null
    fetchError.value = null
    createLoading.value = null
    createError.value = null
  }

  const GET_NEWS_REQUEST = () => {
    fetchError.value = null
    fetchLoading.value = true
  }

  const GET_NEWS_SUCCESS = () => {
    fetchLoading.value = false
    fetchError.value = null
  }

  const GET_NEWS_FAILURE = (error) => {
    fetchLoading.value = false
    fetchError.value = error
  }
  const CREATE_NEWS_REQUEST = () => {
    createError.value = null
    createLoading.value = true
  }

  const CREATE_NEWS_SUCCESS = (newsNew) => {
    createLoading.value = false
    createError.value = null
    news.value = newsNew
  }

  const CREATE_NEWS_FAILURE = (error) => {
    createLoading.value = false
    createError.value = error
  }

  const getNews = (userData) => {
    GET_NEWS_REQUEST()

    const onSuccess = (arg) => {
      if (news.value)
        news.value = [...news.value, ...arg.docs.map(mapNews)]
      else
        news.value = [...arg.docs.map(mapNews)]
      lastVisible.value = arg.docs[arg.docs.length - 1]
      size.value = arg.docs.length
      GET_NEWS_SUCCESS()
    }

    const onError = (error) => {
      console.error(error)
      GET_NEWS_FAILURE(error)
      onErrorShared(error)
    }

    const promise = lastVisible.value
      ? getNextNews(
        userData.company,
        lastVisible.value,
      )
      : getNewsQuery(userData.company)

    promise.then(onSuccess).catch(onError)
  }

  const createNews = async (newsData, userData) => {
    CREATE_NEWS_REQUEST()

    let newNews: NewsModel[] = news.value ? [...news.value] : []
    let newNewsRef
    let uploaded

    try {
      newsData.lastUpdateByUser = userData.reference
      newsData.lastUpdateTime = serverTimestamp()

      const imgData = newsData.image
      newsData.image = null

      newNewsRef = await addDoc(
        collectionNews,
        {
          ...newsData.toMap(),
          createdByUser: userData.reference,
        },
      )

      if (imgData) {
        const { imageUrl, imagePath, uploadedData } = await createAndUploadImage(
          userData,
          newNewsRef,
          imgData,
          storage,
        )

        newsData.image = imageUrl
        newsData.imagePath = imagePath
        uploaded = uploadedData
      }

      await updateDoc(
        newNewsRef,
        {
          imagePath: newsData.imagePath,
          image: newsData.image,
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      newsData.reference = newNewsRef
      newNews = [
        newsData,
        ...newNews,
      ]

      CREATE_NEWS_SUCCESS(newNews)

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }
    catch (error) {
      if (uploaded)
        await deleteDoc(uploaded.ref)

      if (newNewsRef)
        await deleteDoc(newNewsRef)

      CREATE_NEWS_FAILURE(error)
      onErrorShared(error)
    }
  }

  const editNews = (newsData, userData) => {
    CREATE_NEWS_REQUEST()

    const newNews = news.value ? [...news.value] : []
    const { reference, image, imagePath } = newsData

    const onError = (error) => {
      console.error(error)
      CREATE_NEWS_FAILURE(error)
      onErrorShared(error)
    }

    const onSuccess = () => {
      const findByReference = ({ reference: referenceValue }) => getIdFromRef(referenceValue) == getIdFromRef(reference)

      const newsIndex = news.value ? news.value.findIndex(findByReference) : 0
      newNews[newsIndex] = newsData
      CREATE_NEWS_SUCCESS(newNews)
      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    const updateNews = () => {
      newsData.lastUpdateByUser = userData.reference
      newsData.lastUpdateTime = serverTimestamp()

      updateDoc(
        reference,
        newsData.toMap(),
      )
        .then(onSuccess)
        .catch(onError)
    }

    if (typeof image !== 'string' && imagePath) {
      const updateImgUrl = (url) => {
        newsData.image = url
        updateNews()
      }

      const getNewImageUrl = (uploaded) => {
        getDownloadURL(uploaded.ref)
          .then(updateImgUrl)
          .catch(onError)
      }

      uploadString(
        storageRef(
          storage,
          imagePath,
        ),
        image.dataUrl,
        'data_url',
      )
        .then(getNewImageUrl)
        .catch(onError)
    }
    else {
      updateNews()
    }
  }

  return {
    news,
    size,
    lastVisible,
    fetchLoading,
    fetchError,
    createLoading,
    createError,
    resetState,
    getNews,
    createNews,
    editNews,
  }
})
