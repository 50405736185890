import { captureException } from '@sentry/browser'
import i18n from '~/i18n'

const codeWrongPassword = 'auth/wrong-password'
const codeUserNotFound = 'auth/user-not-found'
const codeNetworkFailed = 'auth/network-request-failed'
const codeEmailAlreadyInUse = 'auth/email-already-in-use'
const codeTooManyRequests = 'auth/too-many-requests'
const codeInvalid = 'auth/invalid-action-code'
export function getError(error,
  errorNetworkFailed = i18n.t('Errors.errorNetworkFailed'),
  errorFailed = i18n.t('Errors.errorFailed')) {
  if (error.code === codeNetworkFailed)
    return errorNetworkFailed

  captureException(error)
  return errorFailed
}

export function getErrorAuth(error,
  errorInvalidCode = i18n.t('Errors.invalidCode')) {
  if (error.code === codeInvalid)
    return errorInvalidCode

  return getError(error)
}

export function getErrorLogin(error,
  errorWrongPassword,
  errorNetworkFailed,
  errorToManyRequest,
) {
  if (error.code === codeTooManyRequests)
    return errorToManyRequest

  if (error.code === codeWrongPassword || error.code === codeUserNotFound)
    return errorWrongPassword

  return getError(
    error,
    errorNetworkFailed,
  )
}

export function getErrorEmail(error) {
  if (error.code === codeEmailAlreadyInUse)
    return i18n.t('Errors.errorEmailAlreadyInUse')

  return getError(
    error,
  )
}
