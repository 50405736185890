import { addDoc, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { mapCoupons } from '../models/CouponModel'
import { checkCouponCode, collectionCoupons, getCouponsCollection, getNextCouponsCollection } from '../firebase/firestoreCoupons'
import store from '.'

function onErrorShared(error) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}

function initialState() {
  return {
    data: null,
    generatedData: null,
    exists: null,

    size: null,
    lastVisible: null,

    fetchLoading: false,
    fetchError: null,

    createLoading: false,
    createError: null,

  }
}

export function generateRandomText() {
  const charset
      = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const length = 6
  let retVal = ''

  for (let charsNumber = charset.length, index = 0; index < length; ++index)
    retVal += charset.charAt(Math.round(Math.random() * charsNumber))

  return retVal
}

async function checkCode(promoCode) {
  const response = await checkCouponCode(promoCode)
  return response.empty
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getCoupons({
      commit,
      rootState: {
        app: {
          userData: { company },
        },
        coupons: {
          lastVisible,
        },
      },
    }) {
      commit('GET_COUPONS_REQUEST')

      const onSuccess = ({ docs, size }) => {
        commit(
          'GET_COUPONS_SUCCESS',
          {
            data: docs.map(mapCoupons),
            last: docs[docs.length - 1],
            size,
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_COUPONS_FAILURE',
          error,
        )
        onErrorShared(error)
      }

      const promise = lastVisible
        ? getNextCouponsCollection(
          company,
          lastVisible,
        )
        : getCouponsCollection(company)

      promise
        .then(onSuccess)
        .catch(onError)
    },
    async createCoupon(
      {
        commit,
        rootState: {
          app: {
            userData,
          },
        },
      },
      { data },
    ) {
      commit('CREATE_COUPONS_REQUEST')

      let newCouponRef

      try {
        data.lastUpdateByUser = userData.reference
        data.lastUpdateTime = serverTimestamp()

        let code = ''
        let empty = false

        do {
          code = generateRandomText()
          empty = await checkCode(code)
        } while (!empty)

        data.promoCode = code

        newCouponRef = await addDoc(
          collectionCoupons,
          {
            ...data.toMap(),
            createdByUser: userData.reference,
          },
        )

        data.reference = newCouponRef

        commit(
          'CREATE_COUPONS_SUCCESS',
          data,
        )

        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }
      catch (error) {
        if (newCouponRef)
          await deleteDoc(newCouponRef)

        commit(
          'CREATE_COUPONS_FAILURE',
          error,
        )
        onErrorShared(error)
      }
    },
    async createCoupons(
      {
        commit,
        rootState: {
          app: {
            userData,
          },
        },
      },
      { data, count },
    ) {
      commit('CREATE_COUPONS_REQUEST')
      let newCouponRef

      try {
        const dataCopy = data
        dataCopy.lastUpdateByUser = userData.reference
        dataCopy.lastUpdateTime = serverTimestamp()

        const codes = []
        const newData = []
        let code = ''
        let empty = false

        for (let index = 0; index < count; index++) {
          do {
            code = generateRandomText()
            empty = await checkCode(code)
          } while (!empty)
          // @ts-expect-error
          codes[index] = code

          dataCopy.promoCode = code

          newCouponRef = await addDoc(
            collectionCoupons,
            {
              ...dataCopy.toMap(),
              createdByUser: userData.reference,
            },
          )

          const copyData = dataCopy
          copyData.reference = newCouponRef
          // @ts-expect-error
          newData.push(copyData)
          code = ''
        }

        data.reference = newCouponRef
        commit(
          'CREATE_COUPONS_SUCCESS',
          {
            data: newData,
            codes,
          },
        )

        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }
      catch (error) {
        if (newCouponRef) {
          // await deleteDoc(newCouponRef)
        }

        commit(
          'CREATE_COUPONS_FAILURE',
          error,
        )
        onErrorShared(error)
      }
    },

    editCoupon({
      commit,
      rootState: {
        app: { userData },
        coupons: { data },
      },
    }, { newData }) {
      commit('CREATE_COUPONS_REQUEST')

      const onError = (error) => {
        console.error(error)
        commit(
          'CREATE_COUPONS_FAILURE',
          error,
        )
        onErrorShared(error)
      }

      const onSuccess = () => {
        const findByReference = ({ reference: referenceValue }) => getIdFromRef(referenceValue) == getIdFromRef(newData.reference)
        const couponIndex = data.findIndex(findByReference)
        const newCoupon = [...data]
        newCoupon[couponIndex] = newData
        commit(
          'EDIT_COUPONS_SUCCESS',
          newCoupon,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      newData.lastUpdateByUser = userData.reference
      newData.lastUpdateTime = serverTimestamp()
      updateDoc(
        newData.reference,
        newData.toMap(),
      )
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_COUPONS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_COUPONS_SUCCESS(_state, { data, last, size }) {
      _state.size = size
      _state.lastVisible = last
      _state.fetchLoading = false
      _state.fetchError = null
      if (_state.data) {
        _state.data = [
          ..._state.data,
          ...data,
        ]
      }
      else {
        _state.data = data
      }
    },
    GET_COUPONS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },

    CREATE_COUPONS_REQUEST(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    CREATE_COUPONS_SUCCESS(_state, { data, codes }) {
      _state.createLoading = false
      _state.createError = null
      _state.data = [
        ...(_state.data || []),
        ...data,
      ]
      _state.generatedData = codes
    },
    EDIT_COUPONS_SUCCESS(_state, data) {
      _state.createLoading = false
      _state.createError = null
      _state.data = [...data]
    },
    CREATE_COUPONS_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },
    CHECK_COUPONS_REQUEST(_state) {
      _state.exists = null
    },
    CHECK_COUPONS_SUCCESS(_state, exists) {
      _state.exists = exists
    },
    CHECK_COUPONS_FAILURE(_state, error) {
      _state.exists = null
      _state.createError = error
    },
  },
}
