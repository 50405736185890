import CompaniesModule from './companies'
import CouponsModule from './coupons'
import EmployeesModule from './employees'
import InvoicesModule from './invoices'
import MenusModule from './menus'
import MessagesModule from './messages'
import MissingCompaniesModule from './missingCompanies'
import OnlineTransactionReportsModule from './onlineTransactionReports'
import OrderStatisticsModule from './ordersStatistic'
import OrdersModule from './orders'
import PromoCodeStatisticsModule from './promoCodeStatistics'
import ReportBugOrSuggestionModule from './reportBugOrSuggestion'
import RoutesModule from './routes'
import StatisticsModule from './statistics'
import StatsModule from './stats'
import SuppliersModule from './suppliers'

export function regiesterRest(store) {
  store.registerModule(
    'employees',
    EmployeesModule,
  )
  store.registerModule(
    'companies',
    CompaniesModule,
  )
  store.registerModule(
    'menus',
    MenusModule,
  )
  store.registerModule(
    'messages',
    MessagesModule,
  )
  store.registerModule(
    'missingCompanies',
    MissingCompaniesModule,
  )
  store.registerModule(
    'orders',
    OrdersModule,
  )
  store.registerModule(
    'routes',
    RoutesModule,
  )
  store.registerModule(
    'statistics',
    StatisticsModule,
  )
  store.registerModule(
    'stats',
    StatsModule,
  )
  store.registerModule(
    'suppliers',
    SuppliersModule,
  )
  store.registerModule(
    'onlineTransactionReports',
    OnlineTransactionReportsModule,
  )
  store.registerModule(
    'reportBugOrSuggestion',
    ReportBugOrSuggestionModule,
  )
  store.registerModule(
    'orderStatistics',
    OrderStatisticsModule,
  )
  store.registerModule(
    'coupons',
    CouponsModule,
  )
  store.registerModule(
    'promoCodeStatistics',
    PromoCodeStatisticsModule,
  )
  store.registerModule(
    'invoices',
    InvoicesModule,
  )
}
