import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const MASTER_BRANCH = 'master'
const LEGACY_BRANCH = 'legacy'

function getConfig() {
  // @ts-expect-error
  const isProduction = import.meta.env.VITE_CI_COMMIT_REF_NAME === MASTER_BRANCH || import.meta.env.VITE_CI_COMMIT_REF_NAME === LEGACY_BRANCH

  if (isProduction) {
    return {
      // @ts-expect-error
      apiKey: import.meta.env.VITE_API_KEY,
      // @ts-expect-error
      appId: import.meta.env.VITE_APP_ID,
      // @ts-expect-error
      authDomain: import.meta.env.VUE_AUTH_DOMAIN,
      // @ts-expect-error
      databaseURL: import.meta.env.VITE_DATABASE_URL,
      // @ts-expect-error
      measurementId: import.meta.env.VITE_MEASUREMENT_ID,
      // @ts-expect-error
      messagingSenderId: import.meta.env.VITE_MESSAGING_SEND_ID,
      // @ts-expect-error
      projectId: import.meta.env.VITE_PROJECT_ID,
      // @ts-expect-error
      storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
    }
  }
  return {
  // @ts-expect-error
    apiKey: import.meta.env.VITE_STAGING_API_KEY,
    // @ts-expect-error
    appId: import.meta.env.VITE_STAGING_APP_ID,
    // @ts-expect-error
    authDomain: import.meta.env.VUE_AUTH_DOMAIN,
    // @ts-expect-error
    databaseURL: import.meta.env.VITE_STAGING_DATABASE_URL,
    // @ts-expect-error
    measurementId: import.meta.env.VITE_STAGING_MEASUREMENT_ID,
    // @ts-expect-error
    messagingSenderId: import.meta.env.VITE_STAGING_MESSAGING_SEND_ID,
    // @ts-expect-error
    projectId: import.meta.env.VITE_STAGING_PROJECT_ID,
    // @ts-expect-error
    storageBucket: import.meta.env.VITE_STAGING_STORAGE_BUCKET,
  }
}

export const config = getConfig()
export const app = initializeApp(config)
export const auth = getAuth(app)
export const firestore = getFirestore(app)
export const storage = getStorage(app)
export const secondaryFirebaseApp = initializeApp(config, 'primary')
