import { addDoc, arrayRemove, arrayUnion, deleteDoc, getDoc, getDocs, serverTimestamp, updateDoc, writeBatch } from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import {
  collectionMenu,
  getMenuByDay,
  menuByCategoryRef,
  menuCategoriesRef,
} from '../firestoreWrappers'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { onCreate, onUpdate } from '../helpers/actions'
import { createAndUploadImage } from '../helpers/uploadImage'
import { firestore, storage } from '../firebaseCore'
import { generateRandomText } from '../helpers/generateRandomText'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { MenuCategoryModel, mapMenuCategories, mapMenuModel } from '../models/MenuModel'
import store from '.'

const getIdRaw = ({ id }) => id

function initialState() {
  return {
    menuCategoriesRef: null,
    menuCategories: null,
    menuCategoriesLoading: false,

    menus: null,
    menusInactive: null,

    menuOfDay: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },

    fetchMenuOfDayLoading: false,
    fetchMenuOfDayError: null,

    fetchLoading: false,
    fetchError: null,

    createLoading: false,
    createError: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    getMenuCategories({
      commit,
      rootState: {
        app: {
          userData: { company },
        },
      },
    }) {
      commit('GET_MENU_CATEGORIES_REQUEST')

      const onError = (error) => {
        console.error(error)
        commit('GET_MENUS_CATEGORIES_FAILURE')
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const onSuccess = (menuCategories) => {
        if (menuCategories.data().menuCategories?.length) {
          const tmpMenuCategories = mapMenuCategories(menuCategories)
          // sort tmpMenuCategories by priority

          const menuCategoriesData = tmpMenuCategories.sort((a, b) => {
            return a?.priority - b?.priority
          })

          // const menuCategoriesData = menuCategories[0]?.priority
          //   ? tmpMenuCategories.sort((aaa, bbb) => (aaa?.priority || 0) - (bbb?.priority || 0))
          //   : tmpMenuCategories.sort((aaa, bbb) => (aaa?.id || 0) - (bbb?.id || 0))

          commit(
            'GET_MENUS_CATEGORIES_SUCCESS',
            {
              menuCategories: menuCategoriesData,
              menuCategoriesRef: menuCategories.ref,
            },
          )
          store.dispatch(
            'menus/getMenu',
            {
              categoryIndex: 0,
              isActive: true,
            },
          )
        }
        else {
          commit(
            'GET_MENUS_CATEGORIES_SUCCESS',
            {
              menuCategories: [],
              menuCategoriesRef: null,
            },
          )
        }
      }

      getDoc(menuCategoriesRef(company))
        .then(onSuccess)
        .catch(onError)
    },
    getMenu(
      {
        commit,
        state: { menuCategories },
        rootState: {
          app: {
            userData: { company },
          },
        },
      },
      { categoryIndex, isActive },
    ) {
      commit('GET_MENUS_REQUEST')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_MENUS_SUCCESS',
          {
            menus: docs.map(mapMenuModel),
            isActive,
            categoryIndex,
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_MENUS_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          error,
        )
      }

      getDocs(menuByCategoryRef(
        company,
        menuCategories[categoryIndex].id,
        isActive,
      ))
        .then(onSuccess)
        .catch(onError)
    },
    getMenuFromDay(
      {
        commit,
        rootState: {
          app: {
            userData: { company },
          },
        },
      },
      { day },
    ) {
      commit('GET_MENUS_FROM_DAY_REQUEST')
      const onSuccess = ({ docs }) => {
        commit(
          'GET_MENUS_FROM_DAY_SUCCESS',
          {
            menus: docs.map(mapMenuModel),
            day,
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_MENUS_FROM_DAY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          error,
        )
      }
      getDocs(getMenuByDay(
        company,
        day,
      ))
        .then(onSuccess)
        .catch(onError)
    },
    async createNewFood(
      {
        commit,
        state: { menuCategories },
        rootState: {
          app: { userData },
        },
      },
      { menu, menuCategoryIndex },
    ) {
      commit('CREATE_FOOD_REQUEST')

      let newMenuRef
      let uploaded

      const onError = async (error) => {
        if (uploaded)
          await deleteDoc(uploaded.ref)

        if (newMenuRef)
          await deleteDoc(newMenuRef)

        commit(
          'CREATE_FOOD_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      try {
        menu.categoryID = menuCategories[menuCategoryIndex].id
        menu.companyRef = userData.company
        menu.lastUpdateByUser = userData.reference
        menu.lastUpdateTime = serverTimestamp()

        const imgData = menu.image
        menu.image = null

        const imageHighData = menu.imageHigh
        menu.imageHigh = null

        const newMenuData = {
          ...menu.toMap(),
          ...onCreate(userData.reference),
        }

        newMenuRef = await addDoc(
          collectionMenu,
          newMenuData,
        )

        if (imgData) {
          const { imageUrl, imagePath, uploadedData } = await createAndUploadImage(
            userData,
            newMenuRef,
            imgData,
            storage,
          )

          menu.image = imageUrl
          menu.imagePath = imagePath
          uploaded = uploadedData
        }

        if (imageHighData) {
          const { imageUrl, imagePath, uploadedData } = await createAndUploadImage(
            userData,
            newMenuRef,
            imageHighData,
            storage,
            'High',
          )

          menu.imageHigh = imageUrl
          menu.imageHighPath = imagePath
          uploaded = uploadedData
        }

        const onSuccessUpdateMenu = () => {
          menu.menuRef = newMenuRef

          commit(
            'CREATE_FOOD_SUCCESS',
            {
              menuCategoryIndex,
              menu,
            },
          )
          store.dispatch(
            'snackbar/showSnackbar',
            savedDefault,
          )
        }

        const updateData = {
          image: menu.image,
          imagePath: menu.imagePath,
          ...onUpdate(userData.reference),
        }

        updateDoc(
          newMenuRef,
          updateData,
        )
          .then(onSuccessUpdateMenu)
          .catch(onError)
      }
      catch (error) {
        await onError(error)
      }
    },
    async updateFood(
      {
        commit,
        state: { menus, menusInactive },
        rootState: {
          app: {
            userData: { company, reference },
          },
        },
      },
      { menu, menuCategoryIndex, changeStatus },
    ) {
      commit('UPDATE_FOOD_REQUEST')

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_FOOD_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const updateFood = (commitFun, menuData, menuCategoryIndexData) => {
        const onSuccess = () => {
          const getIndex = () => {
            const byRef = ({ menuRef }) => getIdFromRef(menuRef) == getIdFromRef(menuData.menuRef)

            if (menu.isActive) {
              const selectedMenu = changeStatus
                ? menusInactive
                : menus
              return selectedMenu
                ? selectedMenu[menuCategoryIndex].findIndex(byRef)
                : null
            }
            const selectedMenu = changeStatus
              ? menus
              : menusInactive
            return selectedMenu
              ? selectedMenu[menuCategoryIndex].findIndex(byRef)
              : null
          }

          commitFun(
            'UPDATE_FOOD_SUCCESS',
            {
              dishIndex: getIndex(),
              menu: menuData,
              changeStatus,
              menuCategoryIndex: menuCategoryIndexData,
            },
          )
          store.dispatch(
            'snackbar/showSnackbar',
            savedDefault,
          )
        }

        menu.lastUpdateByUser = reference
        menu.lastUpdateTime = serverTimestamp()

        updateDoc(
          menu.menuRef,
          menu.toUpdateMap(),
        )
          .then(onSuccess)
          .catch(onError)
      }

      if (!menu.image || typeof menu.image === 'string') {
        updateFood(
          commit,
          menu,
          menuCategoryIndex,
        )
      }
      else if (!menu.imagePath && menu.image) {
        let uploaded
        let uploadedHigh
        try {
          const imagePath = `${getIdFromRef(company)}/${getIdFromRef(menu.reference)}${generateRandomText()}`
          const imageHighPath = `${imagePath}High`

          menu.imagePath = imagePath
          menu.imageHighPath = imageHighPath

          uploaded = await uploadString(
            ref(
              storage,
              imagePath,
            ),
            menu.image.dataUrl,
            'data_url',
          )

          uploadedHigh = await uploadString(
            ref(
              storage,
              imageHighPath,
            ),
            menu.imageHigh.dataUrl,
            'data_url',
          )

          const imageUrl = await getDownloadURL(uploaded.ref)
          menu.image = imageUrl

          const imageHighUrl = await getDownloadURL(uploadedHigh.ref)
          menu.imageHigh = imageHighUrl

          updateFood(
            commit,
            menu,
            menuCategoryIndex,
          )
        }
        catch (error) {
          if (uploaded)
            await deleteDoc(uploaded.ref)

          if (uploadedHigh)
            await deleteDoc(uploadedHigh.ref)

          onError(error)
        }
      }
      else {
        try {
          let uploadedHigh
          const uploaded = await uploadString(
            ref(
              storage,
              menu.imagePath,
            ),
            menu.image.dataUrl,
            'data_url',
          )

          if (menu.imageHighPath) {
            uploadedHigh = await uploadString(
              ref(
                storage,
                menu.imageHighPath,
              ),
              menu.imageHigh.dataUrl,
              'data_url',
            )
          }
          else {
            const imageHighPath = `${menu.imagePath}High`
            menu.imageHighPath = imageHighPath
            uploadedHigh = await uploadString(
              ref(
                storage,
                imageHighPath,
              ),
              menu.imageHigh.dataUrl,
              'data_url',
            )
          }

          const imageUrl = await getDownloadURL(uploaded.ref)
          const imageHighUrl = await getDownloadURL(uploadedHigh.ref)
          menu.image = imageUrl
          menu.imageHigh = imageHighUrl
          updateFood(
            commit,
            menu,
            menuCategoryIndex,
          )
        }
        catch (error) {
          onError(error)
        }
      }
    },
    createNewCategory(
      {
        commit,
        rootState: {
          app: {
            userData: { company, reference },
          },
        },
      },
      { categoryName, categoryNameEN, active, selectedDate },
    ) {
      commit('CREATE_MENU_CATEGORY_REQUEST')

      const onSuccess = newMenuCategory => () => {
        commit(
          'CREATE_MENU_CATEGORY_SUCCESS',
          newMenuCategory,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'CREATE_MENU_CATEGORY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const onSuccessGetCategories = (menuCategoryData) => {
        const newMenuCategory = new MenuCategoryModel(
          {
            name: categoryName,
            nameEN: categoryNameEN,
            isActive: active,
            orderOnDay: selectedDate,
            id:
              menuCategoryData.data().menuCategories.length > 0
                ? Math.max(...menuCategoryData.data().menuCategories.map(getIdRaw)) + 1
                : 1,
          },
          menuCategoryData.ref,
        )

        updateDoc(
          menuCategoryData.ref,
          {
            menuCategories: arrayUnion(newMenuCategory.toMap()),

            lastUpdateByUser: reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
          .then(onSuccess(newMenuCategory))
          .catch(onError)
      }

      getDoc(menuCategoriesRef(company))
        .then(onSuccessGetCategories)
        .catch(onError)
    },

    async changeActivity(
      {
        commit,
        rootState: {
          app: { userData },
        },
      },
      { data, isActive, selectedCategory },

    ) {
      commit('UPDATE_STATUS_REQUEST')
      try {
        const batch = writeBatch(firestore)

        data.forEach((item) => {
          batch.update(
            item.menuRef,
            {
              isActive: !isActive,
              lastUpdateByUser: userData.reference,
              lastUpdateTime: serverTimestamp(),
            },
          )
        })

        await batch.commit()
        commit(
          'UPDATE_STATUS_SUCCESS',
          {
            data,
            selectedCategory,
            isActive,
          },
        )
      }
      catch (error) {
        commit(
          'UPDATE_STATUS_ERROR',
          error,
        )
      }
    },
    editCategory(
      {
        commit,
        state: { menuCategories },
        rootState: {
          app: { userData },
        },
      },
      { categoryName, categoryNameEN, categoryIndex, active, selectedDate },
    ) {
      commit('UPDATE_MENU_CATEGORY_REQUEST')

      const selectedMenuCategory = menuCategories[categoryIndex]

      const batch = writeBatch(firestore)

      batch.update(
        selectedMenuCategory.menuCategoryRef,
        {
          menuCategories: arrayRemove(selectedMenuCategory.toMap()),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      const menuToUpdate = selectedMenuCategory
      menuToUpdate.name = categoryName
      menuToUpdate.nameEN = categoryNameEN
      menuToUpdate.isActive = active
      menuToUpdate.orderOnDay = selectedDate

      batch.update(
        selectedMenuCategory.menuCategoryRef,
        {
          menuCategories: arrayUnion(menuToUpdate.toMap()),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )

      const onSuccess = () => {
        commit(
          'UPDATE_MENU_CATEGORY_SUCCESS',
          {
            menuToUpdate,
            categoryIndex,
          },
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_MENU_CATEGORY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      batch.commit().then(onSuccess)
        .catch(onError)
    },
    updateCategoryOrder(
      {
        commit,
        rootState: {
          app: { userData },
          menus: {
            // eslint-disable-next-line no-shadow
            menuCategoriesRef,
          },
        },
      },
      data,
    ) {
      commit('UPDATE_MENU_CATEGORY_REQUEST')

      const onSuccess = () => {
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
        commit(
          'UPDATE_MENU_CATEGORY_ORDER_SUCCESS',
          data,
        )
        window.location.reload()
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_MENU_CATEGORY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }
      updateDoc(
        menuCategoriesRef,
        {
          menuCategories: data.map(item => item.toMap()),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_MENU_CATEGORIES_REQUEST(_state) {
      _state.menuCategoriesLoading = true
    },
    GET_MENUS_CATEGORIES_SUCCESS(_state, { menuCategories, menuCategoriesRef: menuCategoriesRef1 }) {
      _state.menuCategoriesLoading = false
      _state.menuCategories = menuCategories
      _state.menuCategoriesRef = menuCategoriesRef1
    },
    GET_MENUS_CATEGORIES_FAILURE(_state) {
      _state.menuCategoriesLoading = false
    },
    UPDATE_STATUS_REQUEST(_state) {
      _state.menuCategoriesLoading = true
    },
    UPDATE_STATUS_SUCCESS(_state, { data, selectedCategory, isActive }) {
      const getId = item => item.menuRef.id

      const moveMenusObjects = []
      const tmpMenus = isActive
        ? _state.menus
        : _state.menusInactive
      const menuRefs = data.map(getId)

      const filterAndUpdateMenuItems = (item) => {
        if (menuRefs.includes(item.menuRef.id)) {
          const tmpItem = item
          tmpItem.isActive = !isActive
          // @ts-expect-error
          moveMenusObjects.push(tmpItem)
          return false
        }
        return true
      }

      tmpMenus[selectedCategory] = tmpMenus[selectedCategory]
        .filter(filterAndUpdateMenuItems)

      if (isActive)
        _state.menus = tmpMenus
      else
        _state.menusInactive = tmpMenus

      if (
        (isActive && _state.menusInactive && _state.menusInactive[selectedCategory])
        || (!isActive && _state.menus && _state.menus[selectedCategory])
      ) {
        const tmpInactive = isActive
          ? _state.menusInactive
          : _state.menus
        tmpInactive[selectedCategory] = [
          ...tmpInactive[selectedCategory],
          ...moveMenusObjects,
        ]
        if (isActive)
          _state.menusInactive = tmpInactive
        else
          _state.menus = tmpInactive
      }

      _state.menuCategoriesLoading = false
    },
    UPDATE_STATUS_ERROR(_state) {
      _state.menuCategoriesLoading = false
    },

    GET_MENUS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_MENUS_SUCCESS(_state, { menus, isActive, categoryIndex }) {
      _state.fetchLoading = false
      _state.fetchError = null
      if (isActive) {
        _state.menus = {
          ..._state.menus || {},
          [categoryIndex]: menus,
        }
      }
      else {
        _state.menusInactive = {
          ..._state.menusInactive || {},
          [categoryIndex]: menus,
        }
      }
    },
    GET_MENUS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },

    GET_MENUS_FROM_DAY_REQUEST(_state) {
      _state.fetchMenuOfDayError = null
      _state.fetchMenuOfDayLoading = true
    },
    GET_MENUS_FROM_DAY_SUCCESS(_state, { menus, day }) {
      _state.fetchMenuOfDayLoading = false
      _state.fetchMenuOfDayError = null
      _state.menuOfDay[day] = menus
    },
    GET_MENUS_FROM_DAY_FAILURE(_state, error) {
      _state.fetchMenuOfDayLoading = false
      _state.fetchMenuOfDayError = error
    },

    UPDATE_FOOD_REQUEST(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    UPDATE_FOOD_SUCCESS(
      _state,
      { dishIndex, menu, changeStatus, menuCategoryIndex },
    ) {
      _state.createLoading = false
      _state.createError = null
      if (dishIndex !== null) {
        if (menu.isActive) {
          if (changeStatus) {
            _state.menusInactive[menuCategoryIndex].splice(
              dishIndex,
              1,
            )
          }
          else {
            const tmpMenus = _state.menus
            const copy = [...tmpMenus[menuCategoryIndex]]
            copy[dishIndex] = menu
            tmpMenus[menuCategoryIndex] = copy
            _state.menus = tmpMenus
          }
        }
        else if (!menu.isActive) {
          if (changeStatus) {
            _state.menus[menuCategoryIndex].splice(
              dishIndex,
              1,
            )
          }
          else {
            const tmpMenus = { ..._state.menusInactive }
            tmpMenus[menuCategoryIndex][dishIndex] = menu
            _state.menusInactive = tmpMenus
          }
        }
      }
    },
    UPDATE_FOOD_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },

    UPDATE_MENU_CATEGORY_REQUEST(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    UPDATE_MENU_CATEGORY_SUCCESS(_state, { menuToUpdate, categoryIndex }) {
      _state.createLoading = false
      _state.createError = null
      _state.menuCategories[categoryIndex] = menuToUpdate
    },
    UPDATE_MENU_CATEGORY_ORDER_SUCCESS(_state, data) {
      _state.createLoading = false
      _state.createError = null
      _state.menuCategories = data
      _state.menus = null
      _state.menus = null
    },
    UPDATE_MENU_CATEGORY_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },

    CREATE_MENU_CATEGORY_REQUEST(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    CREATE_MENU_CATEGORY_SUCCESS(_state, menus) {
      _state.createLoading = false
      _state.createError = null
      _state.menuCategories.push(menus)
    },
    CREATE_MENU_CATEGORY_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },

    CREATE_FOOD_REQUEST(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    CREATE_FOOD_SUCCESS(_state, { menu, menuCategoryIndex }) {
      _state.createLoading = false
      _state.createError = null
      if (menu.isActive) {
        if (_state.menus === null)
          _state.menus = { 0: [menu] }
        else if (_state.menus[menuCategoryIndex])
          _state.menus[menuCategoryIndex].push(menu)
      }
      else if (_state.menusInactive === null) {
        _state.menus = [menu]
      }
      else if (_state.menus[menuCategoryIndex]) {
        _state.menusInactive[menuCategoryIndex].push(menu)
      }
    },
    CREATE_FOOD_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },
  },
}
