import { EmployeeModel } from './EmployeeModel'
import { RatingModel } from './RatingModel'

export class DeliverModel extends EmployeeModel {
  road: string | null
  rating: RatingModel | null
  dailyHistoryRoad: string | null

  constructor(data, reference?) {
    super(
      data,
      reference,
    )
    this.road = data.road || []
    this.rating = data?.rating
      ? new RatingModel(data.rating)
      : null
    this.dailyHistoryRoad = data.dailyHistoryRoad || []
  }

  toMap() {
    return {
      road: this.road,
      rating: this.rating
        ? this.rating.toMap()
        : null,
      dailyHistoryRoad: this.dailyHistoryRoad,
      ...super.toMap(),
    }
  }
}

export function mapDelivers(deliver) {
  return new DeliverModel(
    deliver.data(),
    deliver.ref,
  )
}
