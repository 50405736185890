import { serverTimestamp, updateDoc } from 'firebase/firestore'
import { mapMessages } from '../models/MessageModel'
import { getCompanyCateringOrdersQuery, getMessagesQuery, getVisibleToDoCateringOrdersQuery, updateMessage } from '../firebase/firestoreMessages'
import store from '.'
import { errorDefault } from '~/helpers/snackbar'

function initialState() {
  return {
    data: null,
    cateringOrdersToDo: [],
    cateringOrdersCompany: [],

    loading: null,
    unsubscribe1: null,
    unsubscribe2: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getMessages({ commit }) {
      commit('GET_REQUEST')
      store.dispatch('shared/init')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_SUCCESS',
          docs.map(mapMessages),
        )
        store.dispatch('shared/success')
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      getMessagesQuery()
        .then(onSuccess)
        .catch(onError)
    },
    getCateringOrdersToDo({
      commit,
      rootState: {
        messages: { unsubscribe },
      },
    }) {
      commit('GET_REQUEST')
      store.dispatch('shared/init')

      if (unsubscribe) {
        unsubscribe()
        commit(
          'SAVE_UNSUBSCRIBE',
          null,
        )
      }

      const onSuccess = ({ docs }) => {
        commit(
          'GET_CATERING_ORDERS_TO_DO_SUCCESS',
          docs.map(mapMessages),
        )
        store.dispatch('shared/success')
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const unsubscribeNew = getVisibleToDoCateringOrdersQuery(onSuccess, onError)

      commit(
        'SAVE_UNSUBSCRIBE_1',
        unsubscribeNew,
      )
    },
    getCompanyCateringOrders({ commit }, company) {
      commit('GET_REQUEST')
      store.dispatch('shared/init')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_CATERING_ORDERS_COMPANY_SUCCESS',
          docs.map(mapMessages),
        )
        store.dispatch('shared/success')
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const unsubscribeNew = getCompanyCateringOrdersQuery(company, onSuccess, onError)

      commit(
        'SAVE_UNSUBSCRIBE_2',
        unsubscribeNew,
      )
    },
    updateMessageState({
      commit,
      rootState: {
        app: {
          userData,
        },
      },
    }, { item, status }) {
      const compareRefId = (element1, element2) => element1?.reference?.id === element2?.reference?.id
      const updateStatus = (itemFunc, statusFunc) => message => (
        compareRefId(
          message,
          itemFunc,
        )
          ? {
              ...message,
              status: statusFunc,
            }
          : message
      )

      commit('UPDATE_REQUEST')
      store.dispatch('shared/init')

      const onSuccess = () => {
        commit(
          'UPDATE_SUCCESS',
          updateStatus(
            item,
            status,
          ),
        )
        store.dispatch('shared/success')
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      updateMessage(
        item,
        status,
        userData,
      )
        .then(onSuccess)
        .catch(onError)
    },
    updateCateringOrder({
      commit,
      rootState: {
        app: {
          userData,
        },
      },
    }, { item, status, company }) {
      commit('UPDATE_REQUEST')

      const onSucces = () => {
        commit('UPDATE_CATERING_ORDER_SUCCESS')
        store.dispatch('shared/success')
      }

      const onError = (error) => {
        console.error(error)
        commit('UPDATE_CATERING_ORDER_FAILURE', error)
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      if (status === 'toDo') {
        updateDoc(
          item.reference,
          {
            status,
            company: null,
            isVisible: true,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        ).then(onSucces)
          .catch(onError)
      }
      else {
        updateDoc(
          item.reference,
          {
            status,
            company,
            isVisible: false,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        ).then(onSucces)
          .catch(onError)
      }
    },
  },
  mutations: {
    RESET_STATE(_state) {
      if (_state.unsubscribe1)
        _state.unsubscribe1()
      if (_state.unsubscribe2)
        _state.unsubscribe2()
      Object.assign(
        _state,
        initialState(),
      )
    },
    GET_REQUEST(_state) {
      _state.loading = true
      _state.error = null
    },
    GET_SUCCESS(_state, data) {
      _state.data = data
      _state.loading = false
    },
    GET_CATERING_ORDERS_TO_DO_SUCCESS(_state, data) {
      _state.cateringOrdersToDo = data
      _state.loading = false
    },
    GET_CATERING_ORDERS_COMPANY_SUCCESS(_state, data) {
      _state.cateringOrdersCompany = data
      _state.loading = false
    },
    GET_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
    UPDATE_REQUEST(_state) {
      _state.loading = true
      _state.error = null
    },
    UPDATE_SUCCESS(_state, data) {
      _state.data = data
      _state.loading = false
      _state.error = null
    },
    UPDATE_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
    SAVE_UNSUBSCRIBE_1(_state, unsubscribe) {
      _state.unsubscribe1 = unsubscribe
    },
    SAVE_UNSUBSCRIBE_2(_state, unsubscribe) {
      _state.unsubscribe2 = unsubscribe
    },
    UPDATE_CATERING_ORDER_SUCCESS(_state) {
      _state.loading = false
      _state.error = null
    },
    UPDATE_CATERING_ORDER_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
  },
}
