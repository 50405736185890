import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '../firebaseCore'

export const collectionInvoices = collection(
  firestore,
  'collectiveInvoices',
)

export function getInvoicesCollection(company) {
  return getDocs(query(
    collectionInvoices,
    where(
      'company',
      '==',
      company,
    ),
  ))
}
