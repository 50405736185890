import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import { onAuthStateChanged } from 'firebase/auth'
import { PiniaVuePlugin, createPinia } from 'pinia'
import { createHead } from '@unhead/vue'

// @ts-expect-error
import { UnheadPlugin } from '@unhead/vue/vue2'
import { auth } from './firebaseCore'
import i18n from './i18n'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import vuetify from './vuetify'

const unhead = createHead()
Vue.use(UnheadPlugin)

Vue.config.productionTip = false

let app
const pinia = createPinia()
Vue.use(PiniaVuePlugin)

function onUserChange(user) {
  if (!app) {
    app = new Vue({
      name: 'VueApp',
      // @ts-expect-error
      unhead,
      vuetify,
      store,
      router,
      pinia,
      i18n,
      render: func => func(App),
    }).$mount('#app')
  }
  if (user) {
    store.dispatch(
      'app/checkUserData',
      user,
    )
  }
}

onAuthStateChanged(
  auth,
  onUserChange,
)

// @ts-expect-error
if (import.meta.env.NODE_ENV === 'production') {
  // const sentryToken = '324f84d7c0f44db49d030cce9de69a38@o84629'

  Sentry.init({
    debug: true,
    Vue,
    dsn: 'https://324f84d7c0f44db49d030cce9de69a38@o84629.ingest.us.sentry.io/1832523',
    // dsn: `https://${sentryToken}.ingest.sentry.io/1832523`,
    // @ts-expect-error
    environment: import.meta.env.VITE_CI_COMMIT_REF_NAME === 'master'
      ? 'production'
      : 'staging',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracingOptions: {
      trackComponents: true,
    },
    // @ts-expect-error
    release: import.meta.env.VITE_VERSION || 0,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
