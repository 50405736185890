export class SubsidyModel {
  isUseSubsidyIdentifier: boolean
  subsidyPrice: number | null
  isCompanyPayOverflow: boolean
  canOrderOverflow: boolean
  paymentPercentageSeparation: number
  isMonthlyFixedBudget: boolean
  budgetPeriod: string
  isOrderOnlyAfterAcceptation: boolean

  constructor(data) {
    this.isUseSubsidyIdentifier = data.isUseSubsidyIdentifier || false
    this.subsidyPrice = data.subsidyPrice === null || data.subsidyPrice === undefined ? null : Number(data.subsidyPrice)
    this.isCompanyPayOverflow = data.isCompanyPayOverflow || false
    this.canOrderOverflow = data.canOrderOverflow || false
    this.paymentPercentageSeparation = Number(data.paymentPercentageSeparation) || 0
    this.isMonthlyFixedBudget = data.isMonthlyFixedBudget || false
    this.budgetPeriod = data.budgetPeriod || 'day'
    this.isOrderOnlyAfterAcceptation = data.isOrderOnlyAfterAcceptation || false
  }

  toMap() {
    return {
      isCompanyPayOverflow: this.isCompanyPayOverflow,
      isUseSubsidyIdentifier: this.isUseSubsidyIdentifier,
      subsidyPrice: Number(this.subsidyPrice),
      canOrderOverflow: this.canOrderOverflow,
      paymentPercentageSeparation: this.paymentPercentageSeparation,
      isMonthlyFixedBudget: this.isMonthlyFixedBudget,
      budgetPeriod: this.budgetPeriod,
      isOrderOnlyAfterAcceptation: this.isOrderOnlyAfterAcceptation,
    }
  }
}
