export const ROLE = {
  OWNER: 'owner',
  GET_DELIVER: 'getdeliver',
  DELIVER: 'deliver',
  MANAGER: 'manager',
  MODERATOR: 'moderator',
  CUSTOMER: 'customer',
}

export const COMPNAY_ROLES = [
  ROLE.DELIVER,
  ROLE.OWNER,
  ROLE.MANAGER,
  ROLE.MODERATOR,
  ROLE.CUSTOMER,
]

export const isOwner = displayName => displayName === ROLE.OWNER
export const isGetDeliver = displayName => displayName === ROLE.GET_DELIVER
export const isDeliver = displayName => displayName === ROLE.DELIVER
export const isManager = displayName => displayName === ROLE.MANAGER
export const isModerator = displayName => displayName === ROLE.MODERATOR
export const isCustomer = displayName => displayName === ROLE.CUSTOMER

export function isManagerOrDeliverOrOwner(displayName) {
  return displayName === ROLE.MANAGER
    || displayName === ROLE.DELIVER
    || displayName === ROLE.GET_DELIVER
    || displayName === ROLE.OWNER
}

export const isManagerOrDeliver = displayName => displayName === ROLE.MANAGER || displayName === ROLE.DELIVER

export const isManagerOrGetDeliver = displayName => displayName === ROLE.MANAGER || displayName === ROLE.GET_DELIVER

export const isDeliverOrGetDeliver = displayName => displayName === ROLE.DELIVER || displayName === ROLE.GET_DELIVER

export const isDeliverOrOwner = displayName => displayName === ROLE.DELIVER || displayName === ROLE.OWNER

export const isManagerOrOwner = displayName => displayName === ROLE.MANAGER || displayName === ROLE.OWNER
