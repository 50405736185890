import { collection, deleteDoc, doc, getDoc, getDocs, query, serverTimestamp, where, writeBatch } from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
} from 'firebase/auth'
import { deliver, getdeliver, manager, owner } from '../firestoreWrappers'
import { errorDefault, errorEmail, savedDefault } from '../helpers/snackbar'
import { ROLE } from '../helpers/roles'
import { auth, firestore, secondaryFirebaseApp } from '../firebaseCore'
import { generateRandomText } from '../helpers/generateRandomText'
import { getIdFromRef } from '../helpers/getIdFromRef'
import {
  mapDeliver,
  mapOwners,
} from '../models/ManagerModel'
import { DeliverModel } from '../models/DeliverModel'
import store from '.'
import { CompanyModel } from '~/models/CompanyModel'

function sortByCities(query1, { profile: { cities } }) {
  return query(
    query1,
    where(
      'profile.cities',
      'array-contains-any',
      cities,
    ),
  )
}

function initialState() {
  return {
    delivers: null,
    managers: null,
    owners: null,
    getdelivers: null,

    resetPasswordLoading: false,
    resetPasswordError: null,

    fetchLoading: false,
    fetchError: null,

    fetchDeliversLoading: false,
    fetchDeliversError: null,

    fetchGetDeliversLoading: false,
    fetchGetDeliversError: null,

    createLoading: false,
    createError: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getGetDelivers({
      commit,
      rootState: {
        app: { userData },
        employees: { getdelivers },
      },
    }) {
      if (getdelivers)
        return

      commit('GET_GET_DELIVERS_REQUEST')

      const delivers = [...(getdelivers || [])]

      let deliversQuery = getdeliver(userData)
      if (userData.role == ROLE.GET_DELIVER) {
        // @ts-expect-error
        deliversQuery = sortByCities(
          deliversQuery,
          userData,
        )
      }

      const onSuccess = (deliversDataList) => {
        delivers.push(...deliversDataList.docs.map(mapDeliver))
        commit(
          'GET_GET_DELIVERS_SUCCESS',
          { delivers },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_GET_DELIVERS_FAILURE',
          error,
        )
      }

      getDocs(deliversQuery).then(onSuccess)
        .catch(onError)
    },
    getGetDelivers2({
      commit,
      rootState: {
        app: { userData },
        employees: { getdelivers },
      },
    }) {
      if (getdelivers)
        return

      commit('GET_GET_DELIVERS_REQUEST')

      const delivers = [...(getdelivers || [])]

      let deliversQuery = getdeliver(userData)
      if (userData.role == ROLE.GET_DELIVER || userData.role == ROLE.MANAGER) {
        // @ts-expect-error
        deliversQuery = sortByCities(
          deliversQuery,
          userData,
        )
      }

      const onSuccess = (deliversDataList) => {
        delivers.push(...deliversDataList.docs.map(mapDeliver))
        commit(
          'GET_GET_DELIVERS_SUCCESS',
          { delivers },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_GET_DELIVERS_FAILURE',
          error,
        )
      }

      getDocs(deliversQuery).then(onSuccess)
        .catch(onError)
    },
    searchGetDelivers(
      {
        commit,
        rootState: {
          app: { userData },
          employees: { getdelivers: getDeliversList },
        },
      },
      { lastName },
    ) {
      commit('GET_DELIVERS_REQUEST')
      const delivers = [...(getDeliversList || [])]

      let deliversQuery = getdeliver(userData)
      if (userData.role == ROLE.GET_DELIVER) {
        // @ts-expect-error
        deliversQuery = sortByCities(
          deliversQuery,
          userData,
        )
      }

      getDocs(query(
        deliversQuery,
        where(
          'profile.lastName',
          '==',
          lastName,
        ),
      ))
        .then((deliversDataList) => {
          // eslint-disable-next-line no-shadow
          for (const deliver of deliversDataList.docs) {
            const byDeliverRef = existingDeliver => getIdFromRef(existingDeliver.reference) == getIdFromRef(deliver.ref)

            const isExist = delivers.findIndex(byDeliverRef)
            if (isExist === -1) {
              const deliverData = new DeliverModel(
                deliver.data(),
                deliver.ref,
              )
              delivers.push(deliverData)
            }
          }

          commit(
            'GET_GET_DELIVERS_SUCCESS',
            {
              delivers,
            },
          )
        })
        .catch((error) => {
          commit(
            'GET_DELIVERS_FAILURE',
            error,
          )
        })
    },
    getDelivers({
      commit,
      rootState: {
        app: { userData },
        employees: { delivers: deliversList },
      },
    }) {
      if (deliversList)
        return

      commit('GET_DELIVERS_REQUEST')

      let deliversQuery = deliver(userData)
      if (userData.role == ROLE.GET_DELIVER) {
        // @ts-expect-error
        deliversQuery = sortByCities(
          deliversQuery,
          userData,
        )
      }

      getDocs(deliversQuery)
        .then((deliversDataList) => {
          const delivers = [...(deliversList || [])]
          delivers.push(...deliversDataList.docs.map(mapDeliver))
          commit(
            'GET_DELIVERS_SUCCESS',
            { delivers },
          )
        })
        .catch((error) => {
          console.error(error)
          commit(
            'GET_DELIVERS_FAILURE',
            error,
          )
          store.dispatch(
            'snackbar/showSnackbar',
            errorDefault(error),
          )
        })
    },
    getDelivers2({
      commit,
      rootState: {
        app: { userData },
        employees: { delivers: deliversList },
      },
    }) {
      if (deliversList)
        return

      commit('GET_DELIVERS_REQUEST')

      let deliversQuery = deliver(userData)
      if (userData.role == ROLE.GET_DELIVER || userData.role == ROLE.MANAGER) {
        // @ts-expect-error
        deliversQuery = sortByCities(
          deliversQuery,
          userData,
        )
      }

      getDocs(deliversQuery)
        .then((deliversDataList) => {
          const delivers = [...(deliversList || [])]
          delivers.push(...deliversDataList.docs.map(mapDeliver))
          commit(
            'GET_DELIVERS_SUCCESS',
            { delivers },
          )
        })
        .catch((error) => {
          console.error(error)
          commit(
            'GET_DELIVERS_FAILURE',
            error,
          )
          store.dispatch(
            'snackbar/showSnackbar',
            errorDefault(error),
          )
        })
    },
    async searchDelivers(
      {
        commit,
        rootState: {
          app: { userData },
          employees: { delivers: deliversList },
        },
      },
      { lastName },
    ) {
      commit('GET_DELIVERS_REQUEST')
      const delivers = [...(deliversList || [])]
      let deliversDataList = []

      try {
        let deliversQuery = deliver(userData)
        if (userData.role == ROLE.GET_DELIVER) {
          // @ts-expect-error
          deliversQuery = sortByCities(
            deliversQuery,
            userData,
          )
        }
        // @ts-expect-error
        deliversDataList = await getDocs(query(
          deliversQuery,
          where(
            'profile.lastName',
            '==',
            lastName,
          ),
        ))
        // @ts-expect-error
        // eslint-disable-next-line no-shadow
        for (const deliver of deliversDataList.docs) {
          const byDeliverRef = existingDeliver => getIdFromRef(existingDeliver.reference) == getIdFromRef(deliver.ref)
          const isExist = delivers.findIndex(byDeliverRef)
          if (isExist === -1) {
            const deliverData = new DeliverModel(
              deliver.data(),
              deliver.ref,
            )
            delivers.push(deliverData)
          }
        }
        commit(
          'GET_DELIVERS_SUCCESS',
          { delivers },
        )
      }
      catch (error) {
        commit(
          'GET_DELIVERS_FAILURE',
          error,
        )
      }
    },
    getManagers({
      commit,
      rootState: {
        app: { userData },
      },
    }) {
      commit('GET_MANAGERS_REQUEST')

      const onSuccess = (managersList) => {
        commit(
          'GET_MANAGERS_SUCCESS',
          {
            managers: managersList.docs.map(mapDeliver),
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_MANAGERS_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      let managersQuery = manager(userData)

      if (userData.role == ROLE.GET_DELIVER || userData.role == ROLE.MANAGER) {
        // @ts-expect-error
        managersQuery = sortByCities(
          managersQuery,
          userData,
        )
      }

      getDocs(managersQuery).then(onSuccess)
        .catch(onError)
    },
    getOwners({
      commit,
      rootState: {
        app: { userData },
      },
    }) {
      commit('GET_OWNERS_REQUEST')

      const onSuccess = (ownersList) => {
        commit(
          'GET_OWNERS_SUCCESS',
          { owners: ownersList.docs.map(mapOwners) },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'GET_OWNERS_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      getDocs(owner(userData))
        .then(onSuccess)
        .catch(onError)
    },
    async editEmployee(
      {
        commit,
        rootState: {
          app: { userData },
        },
        state,
      },
      { employee, oldRole },
    ) {
      commit('CREATE_EMPLOYEES_REQUEST')
      const newEmployees = [...(state[`${employee.role}s`] || [])]
      const byRef = user => getIdFromRef(user.reference) == getIdFromRef(employee.reference)
      const employeeIndex = newEmployees.findIndex(byRef)
      try {
        const batch = writeBatch(firestore)

        batch.update(
          employee.reference,
          {
            ...employee.toMap(),
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )

        if (employee?.isOwner || employee?.isOwner === false) {
          const response = await getDoc(userData.company)
          const company = new CompanyModel(
            response.data(),
            response.ref,
          )
          if (
            !company?.agreement
            || !Object.keys(company?.agreement.acceptedBy || {}).includes(getIdFromRef(employee.reference))
          ) {
            batch.update(
              // @ts-expect-error
              company.reference,
              {
                agreement: {
                  ...(company?.agreement || {}),
                  acceptedBy: {
                    // @ts-expect-error
                    ...(company?.agreement?.acceptedBy || {}),
                    [getIdFromRef(employee.reference)]: false,
                  },
                },
                lastUpdateByUser: userData.reference,
                lastUpdateTime: serverTimestamp(),
              },
            )
          }
          else if (employee?.isOwner === false) {
            const agreementCopy = company?.agreement.acceptedBy
            // @ts-expect-error
            delete agreementCopy[getIdFromRef(employee.reference)]
            batch.update(
              // @ts-expect-error
              company.reference,
              {
                agreement: {
                  ...(company?.agreement || {}),
                  acceptedBy: agreementCopy,
                },
                lastUpdateByUser: userData.reference,
                lastUpdateTime: serverTimestamp(),
              },
            )
          }
        }
        await batch.commit()

        if (employeeIndex === -1)
          newEmployees.push(employee)
        else
          newEmployees[employeeIndex] = employee

        // eslint-disable-next-line no-shadow
        const byRef = user => getIdFromRef(user.reference) !== getIdFromRef(employee.reference)

        commit(
          'CREATE_EMPLOYEES_SUCCESS',
          {
            employees: newEmployees,
            employeesOldRole: [...(state[`${oldRole}s`] || [])].filter(byRef),
            oldRole,
            role: employee.role,
          },
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
        if (employee.role === ROLE.DELIVER) {
          const isDeliver = user => user.role === ROLE.DELIVER
          const delivers = newEmployees.filter(isDeliver)
          store.dispatch(
            'suppliers/setDelivers',
            { delivers },
          )
        }
      }
      catch (error) {
        console.error(error)
        commit(
          'CREATE_EMPLOYEES_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorEmail(error),
        )
      }
    },
    resetEmployeePassword(
      { commit },
      email,
    ) {
      commit('RESET_PASSWORD_EMPLOYEES_REQUEST')

      const onSuccess = () => {
        commit('RESET_PASSWORD_EMPLOYEES_SUCCESS')
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }
      const onError = (error) => {
        console.error(error)
        commit(
          'RESET_PASSWORD_EMPLOYEES_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }
      sendPasswordResetEmail(
        auth,
        email,
      )
        .then(onSuccess)
        .catch(onError)
    },
    async createEmployee({
      commit,
      rootState: {
        app: {
          userData,
        },
      },
      state,
    }, { employee }) {
      commit('CREATE_EMPLOYEES_REQUEST')
      const employeesState = state[`${employee.role}s`]
      const newEmployees = employeesState?.length
        ? [...employeesState]
        : []
      let newEmployeeRef
      const batch = writeBatch(firestore)
      const password = generateRandomText()
      const userRole
        = employee.role === ROLE.GET_DELIVER
          ? ROLE.DELIVER
          : employee.role

      let auth2
      try {
        auth2 = getAuth(secondaryFirebaseApp)

        await createUserWithEmailAndPassword(
          auth2,
          employee.profile.email,
          password,
        )
        await updateProfile(
          auth2.currentUser,
          { displayName: userRole },
        )
        await sendEmailVerification(auth2.currentUser)
        await sendPasswordResetEmail(
          auth2,
          employee.profile.email,
        )
        newEmployeeRef = doc(
          collection(
            firestore,
            `${userRole}s`,
          ),
          auth2.currentUser.uid,
        )
        batch.set(
          newEmployeeRef,
          {
            ...employee.toMap(),
            createdByUser: userData.reference,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
        employee.reference = newEmployeeRef

        await batch.commit()
        newEmployees.push(employee)
        commit(
          'CREATE_EMPLOYEES_SUCCESS',
          {
            employees: newEmployees,
            role: employee.role,
          },
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
        await signOut(auth2)
      }
      catch (error) {
        if (auth2.currentUser)
          await deleteUser(auth2.currentUser)

        if (newEmployeeRef)
          await deleteDoc(newEmployeeRef)

        commit(
          'CREATE_EMPLOYEES_FAILURE',
          error,
        )
        console.error(error)
        store.dispatch(
          'snackbar/showSnackbar',
          errorEmail(error),
        )
      }
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_MANAGERS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_MANAGERS_SUCCESS(_state, { managers }) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.managers = managers
    },
    GET_MANAGERS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },

    GET_DELIVERS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchDeliversLoading = true
      _state.fetchLoading = true
    },
    GET_DELIVERS_SUCCESS(_state, { delivers }) {
      _state.fetchLoading = false
      _state.fetchDeliversLoading = false
      _state.fetchError = null
      _state.delivers = delivers
    },
    GET_DELIVERS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchDeliversLoading = false
      _state.fetchError = error
    },

    GET_GET_DELIVERS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchGetDeliversLoading = true
      _state.fetchLoading = true
    },
    GET_GET_DELIVERS_SUCCESS(_state, { delivers }) {
      _state.fetchLoading = false
      _state.fetchGetDeliversLoading = false
      _state.fetchError = null
      _state.getdelivers = delivers
    },
    GET_GET_DELIVERS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchGetDeliversLoading = false
      _state.fetchError = error
    },

    GET_OWNERS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_OWNERS_SUCCESS(_state, { owners }) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.owners = owners
    },
    GET_OWNERS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },
    RESET_PASSWORD_EMPLOYEES_REQUEST(_state) {
      _state.resetPasswordLoading = true
      _state.resetPasswordError = null
    },
    RESET_PASSWORD_EMPLOYEES_SUCCESS(_state) {
      _state.resetPasswordLoading = false
      _state.resetPasswordError = null
    },
    RESET_PASSWORD_EMPLOYEES_FAILURE(_state, error) {
      _state.resetPasswordLoading = false
      _state.resetPasswordError = error
    },

    CREATE_EMPLOYEES_REQUEST(_state) {
      _state.createLoading = true
      _state.createError = null
    },
    CREATE_EMPLOYEES_SUCCESS(
      _state,
      { employees, role, oldRole, employeesOldRole },
    ) {
      _state.createLoading = false
      _state[`${role}s`] = employees
      _state.createError = null
      if (oldRole)
        _state[`${oldRole}s`] = employeesOldRole
    },
    CREATE_EMPLOYEES_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },
  },
}
