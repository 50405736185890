import type { DocumentReference } from 'firebase/firestore'

export class Stats {
  data: string | null
  count?: number | null
  status?: number | null
  price?: number | null
  orders?: number | null
  customerName: string | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.data = data || null
    if (data?.orders) {
      this.orders = data.orders
    }
    else {
      this.count = data.order?.count || 1
      this.status = data.order?.status || null
      this.price = data.order?.price || 0
    }
    this.customerName = data.customerName || null
    this.reference = reference || null
  }

  toMap() {
    return {
      data: this.data,
      customerName: this.customerName,
      status: this.status,
    }
  }
}

export function mapStats(data) {
  return new Stats(
    data.data(),
    data.ref,
  )
}
