<script setup lang="ts">
import { ref } from 'vue'

defineProps<{
  loading: boolean
  title: string
  onClose: () => void
}>()

const valid = ref(true)
</script>

<template>
  <v-form
    v-model="valid"
    lazy-validation
  >
    <v-card
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <slot />
      </v-card-text>

      <v-divider />

      <v-row
        dense
        class="ma-0 pa-3"
      >
        <v-spacer />

        <v-btn
          color="primary"
          text
          @click="onClose"
        >
          <span>
            {{ $t("close") }}
          </span>
        </v-btn>
      </v-row>
    </v-card>
  </v-form>
</template>
